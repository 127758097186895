// ====================================================================================
// Hook for controlling the recorder
// ====================================================================================
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRecorderState } from "@hooks";
import { Utterance } from "@types";
import {
  AppDispatch,
  nextUtterance,
  setState,
  toggleAutoStart,
  uploadCompleted,
} from "@store";
import {
  calculateResults,
  createStream,
  generateWaveform,
  scrollToUtterance,
  startRecording,
  stopRecording,
} from "@utils";

import { useSilenceDetection } from "./useSilenceDetection";
import { useAES } from "./useAES";
import { RecorderContext } from "@context";

/**
 * Hook for controlling the audio recorder
 */
export const useRecorderHandler = () => {
  const { recorderState, upcoming, autoStart, audioDeviceId, autoGain } =
    useRecorderState();
  const dispatch = useDispatch<AppDispatch>();
  const { audioStreamRef, recorderRef } = useContext(RecorderContext);
  const aes = useAES();

  /**
   * Handles starting the recorder if in countdown
   */
  useEffect(() => {
    if (recorderState !== "ARM") return;
    async function run() {
      if (upcoming.length === 0) {
        if (autoStart) dispatch(toggleAutoStart());
        dispatch(setState("IDLE"));
        return;
      }
      await createStream(audioStreamRef, audioDeviceId, autoGain);
      startRecording(audioStreamRef, recorderRef, postRecordHandler);
      dispatch(setState("RECORDING"));
    }
    run();
    // eslint-disable-next-line
  }, [recorderState]);

  /**
   * Hook for detecting silence in the stream
   */
  useSilenceDetection(() => {
    stopRecording(audioStreamRef, recorderRef);
    dispatch(setState("IDLE"));
  });

  /**
   * Called when the recorder is finished returns the data
   */
  async function postRecordHandler(data: Blob) {
    const audioUrl = URL.createObjectURL(data);
    const errors = await calculateResults(data);
    const waveform = await generateWaveform(data);
    const status = errors.length > 0 ? "FAIL" : "LOADING";

    const uData: Utterance = {
      ...upcoming[0],
      audio: audioUrl,
      status,
      errors,
      waveform: waveform,
    };

    // If it passed the front end checks upload and wait for AES results
    if (status === "LOADING") {
      await upload(uData);
      aes.getAESResults(uData);
    }

    await dispatch(nextUtterance(uData));
    scrollToUtterance();
  }

  /**
   * Uploads utterance upon completion
   * @param uData
   */
  const upload = async (uData: Utterance) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const blob = await fetch(uData.audio).then((r) => r.blob());
    if (!blob) return;
    await dispatch(
      uploadCompleted({
        audioBlob: blob,
        textFileId: uData.id,
        verticalId: uData.vertical,
      })
    );
  };
};
