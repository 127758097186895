import React, { useState } from "react";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "@store";
import { signOut } from "@store";
import { ParrotLogo } from "@assets";
import {
  MenuStyled,
  MenuItem,
  MenuLogo,
  MenuOverlay,
  MenuThumb,
} from "./Menu.styled";
import { Heading, Divider, Box, Icon, Text } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  IoLogOut,
  IoHome,
  IoMic,
  IoSettings,
  IoPaperPlane,
  IoRefresh,
} from "react-icons/io5";
import { useAuthState } from "@hooks";
import { Config } from "@config";

type Props = {
  hasBottomBar: boolean;
};

/**
 * Sidebar Menu
 */
export const Menu: React.FC<Props> = ({ hasBottomBar }) => {
  const { loggedIn, email } = useAuthState();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {/*Hamburger Icon*/}
      <MenuThumb onClick={() => setIsOpen(true)} data-testid="menu-thumb">
        <HamburgerIcon />
      </MenuThumb>

      {/*Side Menu*/}
      {isOpen && (
        <MenuOverlay
          $hasBottomBar={hasBottomBar}
          onClick={() => setIsOpen(false)}
        />
      )}
      <MenuStyled
        $isOpen={isOpen}
        height={hasBottomBar ? "calc(100vh - 75px)" : "100vh"}
        data-testid="menu"
      >
        <div>
          <Box mt={12}>
            <MenuLogo onClick={() => setIsOpen(false)}>
              <ParrotLogo />
            </MenuLogo>

            <Heading size="lg" textAlign="center">
              Voice Cloner
            </Heading>
          </Box>

          <Divider my={12} />
          <Box>
            <MenuItem to="/" onClick={() => setIsOpen(false)}>
              <Icon as={IoHome} mr={4} />
              Dashboard
            </MenuItem>
            {/*<MenuItem to="/tutorial" onClick={() => setIsOpen(false)}>*/}
            {/*  <Icon as={IoHelpCircle} mr={4} />*/}
            {/*  Tutorial*/}
            {/*</MenuItem>*/}
            <MenuItem to="/calibration" onClick={() => setIsOpen(false)}>
              <Icon as={IoMic} mr={4} />
              Audio Setup
            </MenuItem>
            <MenuItem to="/review" onClick={() => setIsOpen(false)}>
              <Icon as={IoRefresh} mr={4} />
              Review
            </MenuItem>
            <MenuItem to="/settings" onClick={() => setIsOpen(false)}>
              <Icon as={IoSettings} mr={4} />
              Settings
            </MenuItem>
            <MenuItem
              to="/"
              onClick={() => {
                setIsOpen(false);
                window.open(
                  "https://aflorithmic.typeform.com/to/bgB6iRYz",
                  "_blank",
                  "noopener"
                );
              }}
            >
              <Icon as={IoPaperPlane} mr={4} />
              Report a problem
            </MenuItem>
            {loggedIn && (
              <MenuItem to="/" onClick={() => dispatch(signOut())}>
                <Icon as={IoLogOut} mr={4} />
                Sign Out
              </MenuItem>
            )}
          </Box>

          <Divider my={12} />
        </div>
        <div>
          <Text textAlign="center" mb={4}>
            {email}
          </Text>
          <Text textAlign="center">Version: {Config.version}</Text>
        </div>
      </MenuStyled>
    </>
  );
};
