// ====================================================================================
// Takes in a string and highlights the words based on the indices array from the AES
// ====================================================================================
import styled from "@emotion/styled";

/**
 * Word Highlighter
 * @param props
 * @returns
 */
export const WordHighlight = (props: { text: string; indices: number[] }) => {
  const words = props.text.split(" ");
  return (
    <>
      {words &&
        words.map((item, index) => {
          if (props.indices.includes(index)) {
            return (
              <span key={index}>
                <WordError>{item}</WordError>{" "}
              </span>
            );
          }
          return <span key={index}>{item} </span>;
        })}
    </>
  );
};

const WordError = styled.span`
  // background: #feb2b2;
  // padding: 2px 8px;
  // border-radius: 1rem;
  border-bottom: 2px #feb2b2 solid;
`;
