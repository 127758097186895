import { useSelector } from "react-redux";
import { RootState } from "@store";
import { useMemo } from "react";

export interface VerticalUtterance {
  id: string;
  text: string;
  vertical: string;
}

/**
 * Access to data state
 */
export const useVerticalsState = () => {
  const utterances = useSelector(
    (state: RootState) => state.verticals.verticals
  );
  const verticalsLoading = useSelector(
    (state: RootState) => state.verticals.recordingDataLoading
  );
  const userDataLoading = useSelector(
    (state: RootState) => state.user.userdataLoading
  );
  const fileCount = useSelector((state: RootState) => state.verticals.count);
  const total = useSelector((state: RootState) => state.verticals.total);

  // Join the utterances, maybe changed in the future
  const utterancesJoined = useMemo((): VerticalUtterance[] => {
    const joined: VerticalUtterance[] = [];
    utterances.forEach((vertical) => {
      vertical.utterancesToRecord.forEach((item) =>
        joined.push({ ...item, vertical: vertical.verticalId })
      );
    });
    return joined;
  }, [utterances]);

  return {
    utterances: utterancesJoined,
    utterancesLoading: verticalsLoading || userDataLoading,
    fileCount,
    utterancesRemaining: total - fileCount,
    total: total,
    count: fileCount,
    utterancesRecorded: fileCount,
    verticals: utterances,
  };
};
