// ====================================================================================
// Class for controlling the recorder
// ====================================================================================

// eslint-disable-next-line
const waveEncoder = require("./WaveEncoder");
// eslint-disable-next-line
const { clearTracks } = require("./clearTracks");

const createWorker = (fn: () => void) => {
  const js = fn
    .toString()
    .replace(/^(\(\)\s*=>|function\s*\(\))\s*{/, "")
    .replace(/}$/, "");
  const blob = new Blob([js]);
  return new Worker(URL.createObjectURL(blob));
};

// Let the audio context be global, important! Otherwise it fails after 50-100 recordings (rip voice cloner 1)
let context: AudioContext;

/**
 * Audio Recorder with MediaRecorder API.
 */
class Recorder {
  state: "IDLE" | "RECORDING" | "PAUSED" = "IDLE";

  stream: MediaStream | undefined;

  clone: MediaStream | undefined;

  context: AudioContext | undefined;

  analyser: AnalyserNode | undefined;

  input: MediaStreamAudioSourceNode | undefined;

  processor: AudioWorkletNode | undefined;

  splitter: ChannelSplitterNode | undefined;

  encoder = waveEncoder;

  em: DocumentFragment | undefined;

  mimeType = "audio/wav";

  /**
   * Constructor
   * @param { MediaStream } stream The audio stream to record.
   */
  constructor(stream: MediaStream) {
    // Check if the audio context is supported
    if (!navigator.mediaDevices || !AudioContext) {
      return;
    }
    this.stream = stream;

    this.state = "IDLE";

    this.em = document.createDocumentFragment();

    this.encoder = createWorker(this.encoder);

    // Add event listener
    this.encoder.addEventListener("message", (e: MessageEvent) => {
      const dataEvent = new CustomEvent("dataavailable");
      // @ts-expect-error hello
      dataEvent.data = new Blob([e.data], { type: this.mimeType });
      this.em?.dispatchEvent(dataEvent);

      if (this.state === "IDLE") {
        const stopEvent = new CustomEvent("onstop");
        // @ts-expect-error hello
        stopEvent.data = new Blob([e.data], { type: this.mimeType });
        this.em?.dispatchEvent(stopEvent);
        this.em?.dispatchEvent(new Event("stop"));
      }
    });
  }

  /**
   * start() - Creates the WebAudio context and nodes
   */
  async start() {
    // Don't run if we're not inactive
    if (this.state !== "IDLE") {
      // return this.em.dispatchEvent(error("start"));
      return;
    }
    this.state = "RECORDING";

    // Create the audio context
    if (!context) {
      // context = new AudioContext(this.config);
      context = new AudioContext({ sampleRate: 44100 });
      this.context = context;
    }

    // Not sure why this is needed, neither does Furkan, from code examples
    if (!this.stream) return;
    this.clone = this.stream.clone();

    // Creates a source node from the media stream
    this.input = context.createMediaStreamSource(this.clone);

    // Create the splitter node to only get the first channel
    // Important as different browsers deal with multiple channels differently in their implementation of the anaylser
    this.splitter = context.createChannelSplitter(2);

    // Create analyser node
    this.analyser = context.createAnalyser();
    this.analyser.fftSize = 1024;

    // Creates the processor node, audio worklet to collect the buffers
    await context.audioWorklet.addModule("/recorder.worklet.js");
    this.processor = new AudioWorkletNode(context, "recorder.worklet");

    // Connect input to the analyser
    this.input.connect(this.splitter);

    // Connect the splitter node
    this.splitter.connect(this.analyser);

    // Connects the analyser to the processor
    this.analyser.connect(this.processor);

    // Connects processor to the output
    this.processor.connect(context.destination);

    // Sends the data from the processor node to the encoder
    this.processor.port.onmessage = (e) => {
      if (this.state === "RECORDING") {
        this.encoder.postMessage(["encode", e.data]);
      }
    };
  }

  /**
   * stop() - Stops recording and raises `dataavailable` event with recorded data.
   */
  stop() {
    // Get the blob and cancel everything!
    this.requestData();
    this.cancel();
  }

  /**
   * cancel() - Stops recorded and destroys context and notes
   */
  cancel() {
    this.state = "IDLE";
    clearTracks(this.stream);
    clearTracks(this.clone);
    this.processor?.disconnect();
    this.analyser?.disconnect();
    this.input?.disconnect();
    this.splitter?.disconnect();
  }

  /**
   * Raise a `dataavailable` event containing the captured media and returns the combined .wav file
   */
  requestData() {
    return this.encoder.postMessage(["dump", context.sampleRate]);
  }

  /**
   * Wrapper Add an event listener for specified event type.
   * @param event
   * @param callback
   */
  addEventListener(event: string, callback: (e: MessageEvent) => void) {
    // @ts-expect-error Not sure what this type is
    this.em?.addEventListener(event, callback);
  }
}

export { Recorder };
