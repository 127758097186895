// ====================================================================================
// Javascript Entrypoint - Setup the app
// ====================================================================================
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Amplify from "@aws-amplify/core";
import { store } from "@store";
import { Config, amplifyConfigData } from "@config";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import LogRocket from "logrocket";
import { App } from "./App";
import { Theme } from "./theme/Theme";

// Setup Logging only on production
if (Config.isProduction) {
  LogRocket.init("4ioz5n/voice-cloner");
}

// Setup Amplify
Amplify.configure(amplifyConfigData);

// Setup React
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ChakraProvider theme={Theme}>
          <ColorModeScript initialColorMode={Theme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// Allow cypress to access the redux state
// TODO: This doesn't work in latest versions of cypress, its always undefined
// if (window.Cypress !== undefined) {
window.store = store;
// }
