// ====================================================================================
// Hook for starting / stopping the recording from the keyboard / buttons etc
// ====================================================================================
import { scrollToUtterance, stopRecording } from "@utils";
import {
  AppDispatch,
  setAutoStartCount,
  setState,
  toggleIsPaused,
} from "@store";
import { useRecorderState } from "@hooks";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { RecorderContext } from "@context";
import { useToast } from "@chakra-ui/react";

export const useTransportControl = () => {
  const { recorderState, autoStart, isPaused, countdownLength } =
    useRecorderState();
  const dispatch = useDispatch<AppDispatch>();
  const { audioStreamRef, recorderRef } = useContext(RecorderContext);
  const toast = useToast();

  /**
   * Called when the main record button is pressed
   */
  const playControl = () => {
    // Stop recording if we are
    if (recorderState === "RECORDING") {
      stopRecording(audioStreamRef, recorderRef);
      dispatch(setState("IDLE"));
      scrollToUtterance();
    }

    // If we are not using hands free mode
    if (!autoStart) {
      // Start the recording
      if (recorderState === "IDLE") dispatch(setState("ARM"));
      return;
    }

    // Show Toast
    if (!isPaused) {
      toast({
        title: "Hands Free Mode",
        description: "Paused - Press space to continue",
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    // Hands-free Toggle pause and reset countdown
    dispatch(toggleIsPaused());
    dispatch(setAutoStartCount(countdownLength));
  };
  return { playControl };
};
