import { useLayoutEffect, useRef } from "react";

/**
 * Wrapper for requestAnimationFrame
 * @param callback called on each animation frame
 * @param isActive
 * @param fps number of frames per second
 */
export const useAnimationFrame = (
  callback: () => void,
  isActive = true,
  fps = 60
) => {
  const callbackRef = useRef<() => void>(callback);
  const frame = useRef<number>();
  const last = useRef(performance.now());

  callbackRef.current = callback;

  /**
   * Called on each frame
   * @param now
   */
  const animate = (now: number) => {
    if (!isActive) return;

    const delta = now - last.current;

    if (callbackRef.current && delta > 1000 / fps) {
      callbackRef.current();
      last.current = now;
    }

    frame.current = requestAnimationFrame(animate);
  };

  // Starts and removes the animation frame
  useLayoutEffect(() => {
    if (typeof performance === "undefined" || typeof window === "undefined") {
      return;
    }

    // Start the animation frame cycle if we're active
    if (isActive) frame.current = requestAnimationFrame(animate);
    else if (frame.current) cancelAnimationFrame(frame.current);

    return () => {
      if (frame.current) cancelAnimationFrame(frame.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
};
