import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VoiceClonerAPI } from "@api";
import { useAuthState } from "@hooks";
import { AppDispatch, prepareUtterances } from "@store";
import { ApiUtterance, Utterance, Vertical } from "@types";
import { Howl } from "howler";

export interface ReviewUtterance extends ApiUtterance {
  selected: boolean;
}

/**
 * Handles the logic for the review page
 * @returns
 */
export const useReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { sub } = useAuthState();

  const [data, setData] = useState<ReviewUtterance[]>();
  const [dataLoading, setDataLoading] = useState(true);
  const [verticalList, setVerticalList] = useState<Vertical[]>([]);
  const [selectedVertical, setSelectedVertical] = useState<string>();
  const audio = useRef<Howl | undefined>();
  const [playbackId, setPlaybackId] = useState<string>("");
  const [playbackLoading, setPlaybackLoading] = useState(false);

  /**
   * Get the data on page load
   */
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  /**
   * Gets the list of users verticals from the API
   * @returns
   */
  const fetch = async () => {
    setDataLoading(true);
    try {
      const res = await VoiceClonerAPI.getAllVerticals();
      if (!res) return;
      setVerticalList(res);
      setSelectedVertical(res[res.length - 1].verticalId);
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  };

  /**
   * Update the utterance list when the vertical changes
   */
  useEffect(() => {
    const chosenVertical = verticalList.filter(
      (vertical: Vertical) => vertical.verticalId === selectedVertical
    );
    if (chosenVertical.length === 0) return;
    const newRes = chosenVertical[0].utterancesRecorded.map(
      (item: ApiUtterance) => {
        return { ...item, selected: false } as ReviewUtterance;
      }
    );
    setData(newRes);
  }, [selectedVertical, verticalList]);

  /**
   * Handles selecting an item
   * @param id
   * @returns
   */
  const select = (id: string) => {
    if (!data) return;
    const newData = data.map((item) => {
      if (item.id === id) item.selected = !item.selected;
      return item;
    });
    setData(newData);
  };

  /**
   * Starts the retake process with the selected utterances
   * @returns
   */
  const start = () => {
    if (!data) return;
    const toRecord = data?.filter((item) => item.selected);
    const utterances = toRecord.map((item) => {
      return { vertical: selectedVertical, id: item.id, text: item.text };
    }) as Utterance[];
    dispatch(
      prepareUtterances({ utterances: utterances, previousPage: "/review" })
    );
    navigate("/record");
  };

  /**
   * Stops the audio player and removes the instance of howl
   */
  const onEnd = () => {
    if (audio.current) {
      audio.current.stop();
      audio.current.unload();
    }
    setPlaybackId("");
    audio.current = undefined;
  };

  /**
   * Gets the audio file from the BE, plays the file
   * @param id
   * @returns
   */
  const getFile = async (id: string) => {
    // Stop the playback if its already playing and returns
    if (id === playbackId && audio.current?.playing) {
      onEnd();
      return;
    }
    onEnd();
    setPlaybackId(id);
    setPlaybackLoading(true);

    // Get audio file from API
    if (!selectedVertical || !sub || !id) return;
    const res = await VoiceClonerAPI.getUtteranceFile({
      userId: sub,
      verticalId: selectedVertical,
      utteranceId: id,
    });

    if (!res) {
      setPlaybackLoading(false);
      return;
    }

    audio.current = new Howl({
      src: res,
      format: "WAV",
      html5: true,
      onend: () => onEnd(),
    });
    audio.current.play();

    setPlaybackLoading(false);
  };

  return {
    data,
    dataLoading,
    verticalList,
    selectedVertical,
    setSelectedVertical,
    select,
    start,
    getFile,
    playbackId,
    isPlaying: audio.current?.playing,
    playbackLoading,
    hasChosen: data && data?.filter((item) => item.selected).length > 0,
  };
};
