import { arrayBufferToDb } from "@utils";

export type ICalibrationResults = {
  result: "PASS" | "LOUD" | "QUIET";
  peakDb: number;
  rmsDb: number;
};

/**
 * checkMicLevel - process the audioBlob data
 * @param data audio Blob
 * @return iCalibrationResults
 */
export const checkMicLevel = async (
  data: Blob
): Promise<ICalibrationResults> => {
  const { peakDb, rmsDb } = await arrayBufferToDb(data);

  // Return data
  const res: ICalibrationResults = {
    result: "PASS",
    peakDb,
    rmsDb,
  };
  if (peakDb > -6.0) res.result = "LOUD";
  if (peakDb < -22.0) res.result = "QUIET";
  return res;
};

/**
 * checkNoiseLevel - Check if the background noise is acceptable
 * @param data audio Blob
 * @param prevPeakDb peakDb to compare (should be at least -20db higher)
 * @return iCalibrationResults
 */
export const checkNoiseLevel = async (
  data: Blob,
  prevPeakDb: number
): Promise<ICalibrationResults> => {
  const { peakDb, rmsDb } = await arrayBufferToDb(data);

  const res: ICalibrationResults = {
    result: "PASS",
    peakDb,
    rmsDb,
  };
  if (rmsDb > -57.0) res.result = "LOUD";
  if (peakDb > prevPeakDb - 20.0) res.result = "LOUD";
  return res;
};
