import { clearTracks, Recorder } from "@utils/recorder";
import { MutableRefObject } from "react";

/**
 * Creates the audio stream
 */
export async function createStream(
  audioStreamRef: MutableRefObject<MediaStream | undefined>,
  audioDeviceId: string,
  autoGain = false
) {
  await navigator.mediaDevices
    .getUserMedia({
      audio: {
        autoGainControl: autoGain,
        noiseSuppression: false,
        echoCancellation: false,
        deviceId: audioDeviceId,
      },
    })
    .then((stream) => {
      audioStreamRef.current = stream;
    })
    .catch((e) => console.error(e));
}

/**
 * Starts recording and sets up onStop event listener
 */
export const startRecording = (
  audioStreamRef: MutableRefObject<MediaStream | undefined>,
  recorderRef: MutableRefObject<Recorder | undefined>,
  onStop: (data: Blob) => void
) => {
  if (audioStreamRef?.current) {
    recorderRef.current = new Recorder(audioStreamRef.current);
    recorderRef?.current.start();

    // Called when the recording is stopped
    recorderRef.current.addEventListener("onstop", (e: MessageEvent) => {
      if (e.data) {
        onStop(e.data);
      } else {
        console.error("No data");
      }
    });
  }
};

/**
 * Stops the recording and clears the refs / tracks
 */
export const stopRecording = (
  audioStreamRef: MutableRefObject<MediaStream | undefined>,
  recorderRef: MutableRefObject<Recorder | undefined>
) => {
  if (audioStreamRef?.current) {
    recorderRef.current?.stop();
    clearTracks(audioStreamRef?.current);
    // audioStreamRef.current = null;
    recorderRef?.current?.cancel();
  }
};
