// ====================================================================================
// Functions for evaluating the audio
// ====================================================================================
import { AESUtteranceResponse, UtteranceError } from "@types";
import { arrayBufferToFloat32, bufferToPeakDb } from "@utils/Audio";

/**
 * Generates data for the waveform from a Blob
 * @param data: Blob
 * @param chunks: number of chunks to create
 * @return number[]: array of decibel values
 */
export const generateWaveform = async (
  data: Blob,
  chunks = 200
): Promise<number[]> => {
  const floatBuffer = await arrayBufferToFloat32(data);
  const chunkLength = Math.floor(floatBuffer.length / chunks); // Divide in 200 chunks
  const res = [];
  for (let i = 0; i < floatBuffer.length; i += chunkLength) {
    const x = bufferToPeakDb(floatBuffer, i, chunkLength);
    res.push(x);
  }
  return res;
};

/**
 * Checks the audio for each utterance and returns errors
 * @param data
 * @return Promise<UtteranceError[]>
 */
export const calculateResults = async (
  data: Blob
): Promise<UtteranceError[]> => {
  // Take the blob, convert to float32 and calculate peak values
  const floatBuffer = await arrayBufferToFloat32(data);
  const peakDb = bufferToPeakDb(floatBuffer);
  const peakDbStart = bufferToPeakDb(floatBuffer, 0, 441);
  const peakDbEnd = bufferToPeakDb(floatBuffer, floatBuffer.length - 441, 441);
  const tooShort = floatBuffer.length < 14700;
  return checkUtteranceErrors(peakDb, tooShort, peakDbStart, peakDbEnd);
};

/**
 * FE Evaluation - Takes the values and generates the error messages
 * @param peakDb
 * @param tooShort
 * @param peakDbStart
 * @param peakDbEnd
 */
export function checkUtteranceErrors(
  peakDb: number,
  tooShort: boolean,
  peakDbStart: number,
  peakDbEnd: number
): UtteranceError[] {
  const errors: UtteranceError[] = [];

  if (peakDb < -24.0)
    errors.push({
      type: "TOO_QUIET",
      name: "Quiet",
      description:
        "This volume of this recording is too quiet and is not ideal for training your voice model. Run the calibration tool to correct your gain level.",
    });

  if (peakDb > -0.1)
    errors.push({
      type: "CLIPPED",
      name: "Clipped",
      description:
        "The volume of this recording was too loud and has become distorted. Run the calibration tool to correct your gain level.",
    });

  if (tooShort)
    errors.push({
      type: "TOO_SHORT",
      name: "Too Short",
      description:
        "The length of this recording is too short to be used to train the voice model.",
    });

  if (peakDbStart > -24.0) {
    // errors.push({
    //   type: "START_CUT",
    //   name: "Start was cut off",
    //   description:
    //     "You started speaking too soon and the recorder did not capture the first word. Please retake and wait for the box to turn red before speaking.",
    // });
  }

  if (peakDbEnd > -24.0) {
    // errors.push({
    //   type: "END_CUT",
    //   name: "Ending was cut short",
    //   description:
    //     "You started stopped the recorder too early and did not capture the last word. Please retake this recording.",
    // });
  }

  return errors;
}

/**
 * BE Evaluation - Calculate the Errors returned from the AES
 * @param feedback
 * @returns
 */
export const calculateAESErrors = (
  feedback: AESUtteranceResponse
): UtteranceError[] => {
  const errors: UtteranceError[] = [];

  if (feedback.valid_audio === 0) {
    errors.push({
      type: "INVALID",
      name: "Invalid File",
      description: "Please re-record this sentence.",
    });
    return errors;
  }
  if (feedback.assess_background_noise_passed === 0)
    errors.push({
      type: "BACKGROUND_NOISE",
      name: "Background Noise",
      description:
        "Our assessment indicates that there is a lot of noise in your environment. Please try moving to a quieter space.",
    });
  if (feedback.assess_text_coverage_passed === 0)
    errors.push({
      type: "TEXT_COVERAGE",
      name: "Missing Words",
      description:
        "Our assessment indicates that some words from the script have been skipped. Please read all the words displayed in the sentence.",
      missedWords: feedback.missed_words,
      missedIndices: feedback.missed_word_indices,
    });
  if (feedback.assess_reverb_passed === 0)
    errors.push({
      type: "REVERB",
      name: "Reverb",
      description:
        "Our assessment indicates that there is too much echo in your recording environment. Please try moving to a quieter space.",
    });
  if (feedback.assess_interrupted_passed === 0)
    errors.push({
      type: "INTERRUPTED",
      name: "Interrupted",
      description:
        "Our assessment indicates that some words from the script have been skipped. Please read all the words displayed in the sentence.",
    });
  if (feedback.assess_too_loud_passed === 0)
    errors.push({
      type: "TOO_LOUD",
      name: "Too Loud",
      description:
        "Our assessment indicates that your recording is too loud. Please reduce the volume of your microphone.",
    });
  if (feedback.assess_too_quiet_passed === 0)
    errors.push({
      type: "TOO_QUIET",
      name: "Too Quiet",
      description:
        "Our assessment indicates that your recording is too quiet. Please increase the volume of your microphone.",
    });
  if (feedback.assess_digital_noise_passed === 0)
    errors.push({
      type: "DIGITAL_NOISE",
      name: "Digital Noise",
      description:
        "There seems to be an internal issue with your microphone. Please try using another microphone to continue.",
    });
  return errors;
};
