// ====================================================================================
// Utilities for the stages
// ====================================================================================
import { MenuStage, Vertical } from "@types";

/**
 * Converts verticals into the stages for the dashboard
 * @param verticals
 * @param hasRunCalibration
 * @returns
 */
export const generateStagesFromVerticals = (
  verticals: Vertical[],
  hasRunCalibration: boolean
): MenuStage[] => {
  let foundNext = false;

  // Add in all the verticals
  return verticals.map((item) => {
    // Disable subsequent stages if this one isn't complete
    const nextStage = !foundNext;
    if (item.summary.percentage !== 1) foundNext = true;
    return {
      name: item.name !== "" ? item.name : item.verticalId,
      type: "VERTICAL",
      verticalId: item.verticalId,
      description: item.description,
      complete: item.summary.percentage === 1,
      percentage: item.summary.count / item.summary.total,
      disabled: !hasRunCalibration || !nextStage,
      estimatedTime: Math.ceil((item.summary.total - item.summary.count) / 8),
      count: item.summary.count,
      total: item.summary.total,
      previewUrl: "",
    };
  });
};

/**
 * Returns a stage object for the dashboard
 * @param hasRunCalibration
 * @returns
 */
export const stageAudioSetup = (hasRunCalibration: boolean): MenuStage => {
  return {
    name: "Audio Setup",
    type: "SETUP",
    verticalId: "",
    description: "Configure your audio setup for the best results",
    complete: hasRunCalibration,
    percentage: 1,
    disabled: false,
    estimatedTime: 2,
    count: 0,
    total: 0,
    previewUrl: "",
  };
};

/**
 * builds the voice builder stage
 * @param status
 * @param disabled
 * @returns
 */
export const stageBuildModel = (
  status: string,
  verticals: Vertical[]
): MenuStage => {
  return {
    name: "Create a Voice",
    type: "MODEL",
    verticalId: "",
    description:
      "Once you have completed the preceding steps, click below to start building your voice model.",
    complete: status !== "UNKNOWN",
    percentage: 0,
    disabled: verticals[1]?.summary.count < 100,
    estimatedTime: 60,
    count: 0,
    total: 0,
    previewUrl: "",
  };
};

/**
 * Builds stage for missing scripts
 * @returns
 */
export const stageMissingScripts = (): MenuStage => {
  return {
    name: "Missing Scripts",
    type: "VERTICAL",
    verticalId: "null",
    description:
      "We're sorry, something went wrong with your account and the scripts could not be loaded. Please contact us to solve this issue",
    complete: false,
    percentage: 0,
    disabled: true,
    estimatedTime: 0,
    count: 0,
    total: 10,
    previewUrl: "",
  };
};
