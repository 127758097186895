import { useSelector } from "react-redux";
import { RootState } from "@store";

/**
 * Access to data state
 */
export const useAuthState = () => {
  const email = useSelector((state: RootState) => state.auth.email);
  const username = useSelector((state: RootState) => state.auth.username);
  const name = useSelector((state: RootState) => state.auth.name);
  const org = useSelector((state: RootState) => state.auth.org);
  const sub = useSelector((state: RootState) => state.auth.sub);
  const role = useSelector((state: RootState) => state.auth.role);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const collectName = useSelector((state: RootState) => state.auth.collectName);
  const authStage = useSelector((state: RootState) => state.auth.authStage);

  return {
    email,
    username,
    name,
    org,
    sub,
    role,
    loggedIn,
    collectName,
    authStage,
  };
};
