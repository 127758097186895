import { useEffect, useRef } from "react";

/**
 * Custom Hooks for executing a callback from a timeout
 * @param callback
 * @param delay
 * @param active
 */
export const useInterval = (
  callback: () => void,
  delay: number,
  active = false
) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (active) {
      const id = setInterval(
        () => savedCallback.current && savedCallback.current(),
        delay
      );
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay, active]);
};
