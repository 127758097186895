import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

export const BarContainer = styled(Box)`
  height: 75px;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  align-items: center;
  overflow: hidden;
`;

BarContainer.defaultProps = {
  _dark: {
    bgGradient: "linear(to-t, gray.700, gray.700)",
    borderColor: "gray.600",
  },
  _light: {
    bgGradient: "linear(to-t, gray.50, gray.50)",
    borderColor: "gray.300",
  },
  borderTop: "1px",
};
