// ====================================================================================
// Stages Controller
// ====================================================================================
import { Box, Flex } from "@chakra-ui/react";
import { useStages } from "../hooks";
import { StageInfo } from "./StageInfo";
import { StageCard } from "./StageCard";

/**
 * Draws the stages on the dashboard
 * @constructor
 */
export const Stages = (props: {
  recordOnClick: () => void;
  buildOnClick: () => void;
  builderIsError: boolean;
  builderMsg: string;
  builderLoading: boolean;
}) => {
  const data = useStages(props.recordOnClick, props.buildOnClick);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column-reverse", md: "row" }}
      gap={8}
    >
      {/*Cards*/}
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        width={{ base: "100%", md: "50%" }}
        data-testid="stages"
      >
        {data.menuStages.map((item) => (
          <Flex
            key={item.name}
            direction="column"
            alignItems="center"
            width="100%"
          >
            <StageCard
              stage={item}
              selectedStage={data.selectedStage}
              onClick={data.setSelectedStage}
            />
          </Flex>
        ))}
      </Flex>

      {/*Side Box*/}
      <StageInfo
        selectedStage={data.selectedStage}
        onStart={data.onStart}
        builderIsError={props.builderIsError}
        builderMsg={props.builderMsg}
        builderLoading={props.builderLoading}
      />
    </Box>
  );
};
