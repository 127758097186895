// ====================================================================================
// Main controller for the cards layout
// ====================================================================================
import React from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import { Wrapper } from "./Main.styled";
import { Card } from "../card";
import { useEndOfStage } from "../../hooks";
import { useRecorderState } from "@hooks";
import { useTransportControl } from "../../hooks/useTransportControl";

/**
 * Container and layout controller for the cards
 */
export const Main = () => {
  const { upcoming, completed } = useRecorderState();
  const { retakeFailed, next, isLoading } = useEndOfStage();
  const { playControl } = useTransportControl();

  return (
    <Box m={{ base: 2, md: 0 }}>
      <Wrapper>
        {/* Render the completed cards */}
        {completed.map((item) => {
          return <Card data={item} key={item.id} />;
        })}

        {/* Render the upcoming cards */}
        {upcoming.map((item, index) => {
          if (index === 0)
            return (
              <Card
                data={item}
                key={item.id}
                current={true}
                recordOnClick={playControl}
              />
            );
          return <Card data={item} key={item.id} />;
        })}

        {/* Render the stage complete if no more cards */}
        {upcoming.length === 0 && (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "120px",
            }}
            id="focused"
          >
            <Heading color="white" my={12}>
              Stage Complete!
            </Heading>
            {completed.filter((item) => item.errors && item.errors.length > 0)
              .length > 0 ? (
              <Button onClick={() => retakeFailed()}>
                Retake Failed Utterances
              </Button>
            ) : (
              <Button
                onClick={() => next()}
                isLoading={isLoading}
                disabled={
                  completed.filter((item) => item.status === "LOADING").length >
                  0
                }
              >
                Next
              </Button>
            )}
          </div>
        )}
      </Wrapper>
    </Box>
  );
};
