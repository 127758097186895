import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Returns a boolean if the bottom bar should be shown
 */
export const useShowBottomBar = () => {
  const [showBottomBar, setShowBottomBar] = useState<boolean>(false);
  const location = useLocation();

  /**
   * Controls whether the bottom bar is shown
   */
  useEffect(() => {
    if (
      location.pathname === "/record" ||
      location.pathname === "/calibration"
    ) {
      setShowBottomBar(true);
    } else {
      setShowBottomBar(false);
    }
  }, [location]);

  return showBottomBar;
};
