// ====================================================================================
// Loads the utterances into the recorder
// ====================================================================================
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch, getVerticalData, prepareUtterances } from "@store";
import { useDispatch } from "react-redux";
import { useUserState, useVerticalsState } from "@hooks";
import { Config } from "@config";

/**
 * Handles loading the utterances from the api and loading them into the recorder
 */
export const useUtterances = () => {
  const { utterances, fileCount, utterancesLoading, utterancesRemaining } =
    useVerticalsState();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { hasLoadedUserData } = useUserState();

  /**
   * Load the vertical data into the state
   */
  useEffect(() => {
    if (!hasLoadedUserData) return;
    dispatch(getVerticalData());
    // eslint-disable-next-line
  }, [hasLoadedUserData]);

  /**
   * Load utterances into recorder and then go to recording screen
   */
  const handleStart = () => {
    const temp = [...utterances].splice(0, Config.utterancesPerStage);
    dispatch(prepareUtterances({ utterances: temp, previousPage: "/" }));
    navigate("/record");
  };

  return {
    handleStart,
    isLoading: utterancesLoading,
    remaining: utterancesRemaining,
    recorded: fileCount,
  };
};
