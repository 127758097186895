import React from "react";
import { Grid, Flex, Button } from "@chakra-ui/react";
import { ErrorIcon, SuccessIcon } from "@assets";

export interface Props {
  children: React.ReactNode;
  type: "PASS" | "LOUD" | "QUIET" | undefined;
  retryOnClick: () => void;
  nextOnClick: () => void;
}

/**
 * Result card with colors based on the state of the setup
 */
export const ResultCard: React.FC<Props> = ({
  children,
  type,
  retryOnClick,
  nextOnClick,
}) => {
  let color = ["gray.200", "gray.500"];
  if (type === "LOUD" || type === "QUIET") color = ["red.100", "red.500"];
  if (type === "PASS") color = ["green.100", "green.500"];

  return (
    <Grid
      height="100%"
      p={6}
      borderRadius="6px"
      placeItems="center"
      borderWidth="1px"
      textAlign="center"
      _dark={{ borderColor: color[1] }}
      _light={{ borderColor: color[0] }}
    >
      <Flex direction="column" align="center">
        {type === "PASS" && <SuccessIcon my={8} width="48x" height="48px" />}
        {(type === "LOUD" || type === "QUIET") && (
          <ErrorIcon my={8} width="48x" height="48px" />
        )}
        {children}
        {(type === "LOUD" || type === "QUIET") && (
          <Button colorScheme="red" mt={8} onClick={() => retryOnClick()}>
            Retry
          </Button>
        )}
        {type === "PASS" && (
          <Button colorScheme="green" mt={8} onClick={() => nextOnClick()}>
            Next
          </Button>
        )}
      </Flex>
    </Grid>
  );
};
