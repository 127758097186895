// ====================================================================================
// Collect Name Page - First/Last/Company name
// ====================================================================================
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { submitName } from "@store";
import { useEnterKey } from "@hooks";
import {
  Text,
  Input,
  FormLabel,
  Button,
  Flex,
  FormControl,
} from "@chakra-ui/react";
import { AuthHeader } from "./AuthHeader";

/**
 * Collect name component
 */
export const CollectName: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.auth.nameLoading);
  const error = useSelector((state: RootState) => state.auth.nameError);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  useEnterKey(() => onSubmit());

  /**
   * Handles the submit button
   */
  const onSubmit = () => {
    if (firstName !== "" && lastName !== "")
      dispatch(
        submitName({ _name: `${firstName}_${lastName}`, company: company })
      );
  };

  return (
    <>
      <AuthHeader title="User Details" />
      <Text mb={8}>Fill out the details below to get started.</Text>

      <FormControl mb={8} isInvalid={error !== ""}>
        <FormLabel htmlFor="first">First Name</FormLabel>
        <Input
          placeholder="John"
          id="first"
          size="lg"
          value={firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const reg = /^\S*$/.test(e.target.value);
            if (reg) setFirstName(e.target.value);
          }}
        />
        {/* <FormErrorMessage>{error}</FormErrorMessage> */}
      </FormControl>

      <FormControl mb={8} isInvalid={error !== ""}>
        <FormLabel htmlFor="last">Last Name</FormLabel>
        <Input
          placeholder="Doe"
          id="last"
          size="lg"
          value={lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const reg = /^\S*$/.test(e.target.value);
            if (reg) setLastName(e.target.value);
          }}
        />
      </FormControl>

      <FormControl mb={8} isInvalid={error !== ""}>
        <FormLabel htmlFor="company">Company Name</FormLabel>
        <Input
          placeholder="Aflorithmic"
          id="company"
          size="lg"
          value={company}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === "") setCompany("");
            const reg = /^[a-zA-Z0-9]+$/.test(e.target.value);
            if (reg) setCompany(e.target.value);
          }}
        />
      </FormControl>

      <Flex justify="center" direction="column">
        <Button
          onClick={() => onSubmit()}
          isLoading={isLoading}
          isDisabled={
            lastName.length === 0 ||
            firstName.length === 0 ||
            company.length === 0
          }
        >
          Next
        </Button>
        <Text color="red.500">{error}</Text>
      </Flex>
    </>
  );
};
