// ====================================================================================
// Error Bubbles
// ====================================================================================
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { ErrorBubble } from "./Card.styled";
import { UtteranceError } from "@types";
import { AppDispatch, showHelpModal } from "@store";
import { useDispatch } from "react-redux";

/**
 * Error Bubbles for the cards
 * @param props
 * @constructor
 */
export function CardErrors(props: {
  status: "PASS" | "FAIL" | "CURRENT" | undefined;
  errors: Array<UtteranceError> | undefined;
}) {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Flex justify="flex-start" mt={4}>
      {/* Utterance Evaluation CardErrors */}
      {props.status === "FAIL" &&
        props.errors &&
        props.errors.map((item, index) => (
          <ErrorBubble
            data-testid="error-bubble"
            key={index}
            onClick={() =>
              dispatch(
                showHelpModal({
                  heading: item.name,
                  content: item.description,
                })
              )
            }
          >
            <Text textAlign="center" fontSize="sm">
              {item.name}
            </Text>
          </ErrorBubble>
        ))}
    </Flex>
  );
}
