// ====================================================================================
// Contains the stage information when the user clicks on a stage card
// ====================================================================================
import { useBuildState } from "@hooks";
import { MenuStage } from "@types";
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";
import { ErrorIcon, SuccessIcon } from "@assets";

/**
 * Stage information box
 * @param props
 * @constructor
 */
export function StageInfo(props: {
  onStart: () => void;
  selectedStage: MenuStage | undefined;
  builderIsError: boolean;
  builderMsg: string;
  builderLoading: boolean;
}) {
  const { status, previewUrls, buildError } = useBuildState();

  return (
    <Box width={{ base: "100%", md: "50%" }} data-testid="stage-info">
      {props.selectedStage && props.selectedStage.name !== "" ? (
        <Flex
          borderWidth="1px"
          borderRadius="6px"
          p={8}
          direction="column"
          justifyContent="space-between"
          minHeight="310px"
        >
          <div>
            <Heading fontSize="xl">{props.selectedStage.name}</Heading>
            <Divider my={6} />
            <Text>{props.selectedStage.description}</Text>

            <Flex justifyContent="space-around" alignItems="center">
              {props.selectedStage.type === "VERTICAL" && (
                <>
                  <CircularProgress
                    value={props.selectedStage.percentage * 100}
                    size="70px"
                    thickness="8px"
                    color={
                      props.selectedStage.complete
                        ? "green.400"
                        : "secondary.400"
                    }
                    trackColor="gray.200"
                    mt={8}
                  >
                    <CircularProgressLabel>
                      {`${Math.ceil(props.selectedStage.percentage * 100)}%`}
                    </CircularProgressLabel>
                  </CircularProgress>
                  <div>
                    <Text textAlign="right" mt={8}>
                      {props.selectedStage.count} / {props.selectedStage.total}{" "}
                    </Text>
                    <Text textAlign="right">recorded</Text>
                  </div>
                </>
              )}
              {props.selectedStage.type === "PREVIEW" && (
                <Flex
                  justifyContent="space-around"
                  alignItems="center"
                  mt={8}
                  maxWidth="270px"
                >
                  <audio controls src={props.selectedStage.previewUrl}>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </Flex>
              )}
              {props.selectedStage.type === "MODEL" && props.builderMsg && (
                <Flex
                  bg={props.builderIsError ? "red.50" : "green.50"}
                  borderRadius="6px"
                  p={4}
                  gap={4}
                  alignItems="center"
                  mt={8}
                >
                  {!props.builderIsError && <SuccessIcon boxSize={8} />}
                  {props.builderIsError && <ErrorIcon boxSize={8} />}
                  <Text>{props.builderMsg}</Text>
                </Flex>
              )}
            </Flex>
          </div>

          {props.selectedStage.type === "MODEL" && buildError !== "" && (
            <Text
              mt={8}
              textAlign="center"
              bg="red.50"
              p={4}
              color="red.500"
              borderRadius="6px"
            >
              {buildError}
            </Text>
          )}

          {props.selectedStage.type === "MODEL" && (
            <Flex justifyContent="center" direction="column" gap={8} mt={8}>
              {previewUrls.length > 0 && <Heading size="md">Previews</Heading>}
              {previewUrls.length > 0 &&
                previewUrls.map((item, index) => (
                  <Flex
                    justifyContent="space-around"
                    alignItems="center"
                    maxWidth="270px"
                    key={index}
                  >
                    <audio controls src={item}>
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </Flex>
                ))}
            </Flex>
          )}

          {props.selectedStage.type !== "PREVIEW" && (
            <>
              <Text my={8} textAlign="center">
                Estimated Time:{" "}
                {props.selectedStage.type === "MODEL" ? (
                  <b>24 hours</b>
                ) : (
                  <b>
                    {props.selectedStage.estimatedTime}{" "}
                    {props.selectedStage.estimatedTime === 1
                      ? "minute"
                      : "minutes"}
                  </b>
                )}
              </Text>

              {(props.selectedStage.complete === false ||
                props.selectedStage.type === "SETUP") && (
                <Button
                  alignSelf="center"
                  disabled={
                    props.selectedStage.disabled ||
                    (props.selectedStage.type === "MODEL" &&
                      status !== "UNKNOWN")
                  }
                  isLoading={
                    props.selectedStage.type === "MODEL" && props.builderLoading
                  }
                  onClick={props.onStart}
                >
                  Start
                </Button>
              )}
            </>
          )}
        </Flex>
      ) : (
        <Grid
          borderWidth="1px"
          placeItems="center"
          borderRadius="6px"
          minHeight="310px"
        >
          <Text color="gray.400">Choose a stage to get started</Text>
        </Grid>
      )}
    </Box>
  );
}
