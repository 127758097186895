// ====================================================================================
// Voice Cloning API
// ====================================================================================
import API from "@aws-amplify/api";
import { authHeader, Config, prod, staging } from "@config";
import {
  AESTotalFeedback,
  AESUtteranceResponse,
  UserData,
  Vertical,
} from "@types";

export const VoiceClonerAPI = {
  /**
   * Loads the user data
   */
  getUserData: async (): Promise<UserData | undefined> => {
    try {
      const data = await API.get("getUserData", ``, {});
      return data.data;
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Updates user data
   */
  setUserData: async (userData: UserData): Promise<UserData | undefined> => {
    try {
      const data = await API.patch("getUserData", "", {
        body: userData,
      });
      return data.data;
    } catch (e) {
      console.log(e);
      return;
    }
  },

  /**
   * Loads utterances
   */
  getAllVerticals: async (): Promise<Vertical[] | undefined> => {
    try {
      const data = await API.get(
        "getText",
        `?quantity=${Config.utterancesPerStage}`,
        {}
      );
      return data.data;
    } catch (err) {
      console.log(err);
      return;
    }
  },

  /**
   * TODO unused atm
   * @param userId
   */
  getTotalAESFeedback: async (
    userId: string
  ): Promise<AESTotalFeedback | undefined> => {
    try {
      const data = await API.get("aesFeedback", `?userId=${userId}`, {});
      const res = data.newAudioFeedback;
      return {
        total: res.file_count,
        passed: Math.round(
          (res.audio_evaluation_passed / res.file_count) * 100
        ),
        passedBackgroundNoise: Math.round(
          (res.assess_background_noise_passed / res.file_count) * 100
        ),
        passedInterrupted: Math.round(
          (res.assess_interrupted_passed / res.file_count) * 100
        ),
        passedClipping: Math.round(
          (res.assess_clipping_passed / res.file_count) * 100
        ),
        passedReverb: Math.round(
          (res.assess_reverb_passed / res.file_count) * 100
        ),
        passedTextCoverage: Math.round(
          (res.assess_text_coverage_passed / res.file_count) * 100
        ),
        passedTooLoud: Math.round(
          (res.assess_too_loud_passed / res.file_count) * 100
        ),
        passedTooQuiet: Math.round(
          (res.assess_too_quiet_passed / res.file_count) * 100
        ),
        passedValidAudio: Math.round((res.valid_audio / res.file_count) * 100),
      } as AESTotalFeedback;
    } catch (err) {
      console.log(err);
      return;
    }
  },

  /**
   * Gets the feedback for an individual utterance while recording
   * @param verticalId
   * @param utteranceId
   * @returns AESUtteranceResponse | undefined
   */
  getAESUtteranceFeedback: async ({
    verticalId,
    utteranceId,
  }: {
    verticalId: string;
    utteranceId: string;
  }): Promise<AESUtteranceResponse | undefined> => {
    try {
      const res = await API.get(
        "aesFeedback",
        `?verticalId=${verticalId}&utteranceId=${utteranceId}`,
        {}
      );
      return res.data.newAudioFeedback;
    } catch (err) {
      console.log(err);
      return;
    }
  },

  /**
   * Uploads file to server
   * @param verticalId
   * @param utteranceId
   * @param audioData
   * @param audioDeviceName
   */
  uploadFile: async (
    verticalId: string,
    utteranceId: string,
    audioData: Blob,
    audioDeviceName: string
  ) => {
    // TODO Doing this manually for the moment, need to research amplify a bit more
    try {
      const { Authorization } = await authHeader();
      const url = `${
        Config.isProduction ? prod.upload : staging.upload
      }?verticalId=${verticalId}&utteranceId=${utteranceId}`;
      // Create request and attach data
      const oReq = new XMLHttpRequest();
      oReq.open("PUT", url, true);
      oReq.setRequestHeader("Authorization", Authorization);
      oReq.setRequestHeader("Content-Type", "audio/wav");
      oReq.setRequestHeader("x-device-info", audioDeviceName);
      oReq.onload = function () {
        console.log(`Uploaded ${verticalId}/${utteranceId}`);
      };
      // Send
      oReq.send(audioData);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Gets the feedback for an individual utterance while recording
   * @param verticalId
   * @param utteranceId
   * @returns AESUtteranceResponse | undefined
   */
  getUtteranceFile: async ({
    userId,
    verticalId,
    utteranceId,
  }: {
    userId: string;
    verticalId: string;
    utteranceId: string;
  }): Promise<string | undefined> => {
    try {
      const res = await API.get(
        "getFile",
        `/${userId}/${verticalId}/${utteranceId}`,
        {
          headers: {
            Accept: "audio/wav",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(res);
      return url;
    } catch (err) {
      console.log(err);
      return;
    }
  },
};
