// ====================================================================================
// Custom Application Icons
// ====================================================================================
import { createIcon } from "@chakra-ui/react";

export const VoiceCloningIcon = createIcon({
  displayName: "VoiceCloningIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0239 0C9.21838 0 6.95193 2.26626 6.95193 5.072V11.9999C6.95193 14.8055 9.21819 17.0719 12.0239 17.0719C14.8299 17.0719 17.0959 14.7787 17.0959 11.9999V5.072C17.0959 2.26621 14.8295 0 12.0239 0ZM9.03092 5.072C9.03092 3.40773 10.3619 2.05255 12.0238 2.05255C13.6857 2.05255 15.0168 3.40773 15.0168 5.072V11.9999C15.0168 13.6623 13.661 14.9928 12.0238 14.9928C10.3614 14.9928 9.03092 13.6371 9.03092 11.9999V5.072ZM4.27642 10.9733C3.71827 10.9733 3.25 11.4416 3.25 11.9997C3.25 16.4898 6.62752 20.2143 10.9976 20.7363V21.947H8.37489C7.81674 21.947 7.34847 22.4152 7.34847 22.9734C7.34847 23.5315 7.81674 23.9998 8.37489 23.9998L15.673 24C16.2311 24 16.6994 23.5317 16.6994 22.9736C16.6994 22.4154 16.2311 21.9472 15.673 21.9472H13.0503V20.7365C17.4203 20.2143 20.7981 16.4897 20.7983 11.9997C20.7983 11.4416 20.33 10.9733 19.7718 10.9733C19.2137 10.9733 18.7454 11.4416 18.7454 11.9997C18.7454 15.6988 15.7231 18.721 12.0241 18.721C8.32505 18.721 5.30283 15.6988 5.30283 11.9997C5.30283 11.4416 4.83457 10.9733 4.27642 10.9733Z"
      fill="currentColor"
    />
  ),
});

export const StopIcon = createIcon({
  displayName: "StopIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <rect width="24" height="24" rx="4" fill="currentColor" />
    </>
  ),
});

export const ErrorIcon = createIcon({
  displayName: "ErrorIcon",
  viewBox: "0 0 32 32",
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#F9DEDE" />
      <path
        d="M9.63594 11.6574C9.06791 11.0894 9.06791 10.1684 9.63594 9.60039C10.204 9.03235 11.1249 9.03235 11.693 9.60039L18.8251 16.7325L22.3639 20.2713C22.9319 20.8393 22.9319 21.7603 22.3639 22.3283C21.7958 22.8963 20.8749 22.8963 20.3068 22.3283L16.768 18.7895L9.63594 11.6574Z"
        fill="#EA5858"
      />
      <path
        d="M20.3069 9.60078C20.875 9.03275 21.7959 9.03275 22.364 9.60079C22.932 10.1688 22.932 11.0898 22.364 11.6578L15.2319 18.7899L11.6931 22.3287C11.1251 22.8967 10.2041 22.8967 9.63605 22.3287C9.06801 21.7607 9.06801 20.8397 9.63605 20.2717L13.1749 16.7329L20.3069 9.60078Z"
        fill="#EA5858"
      />
    </>
  ),
});

export const ScriptIcon = createIcon({
  displayName: "ScriptIcon",
  viewBox: "0 0 32 32",
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#CBD5E0" fillOpacity="0.5" />
      <path
        d="M10.4545 20.1152C9.65122 20.1152 9 19.464 9 18.6607C9 17.8574 9.65122 17.2061 10.4545 17.2061L18.1484 17.2061L21.5455 17.2061C22.3488 17.2061 23 17.8574 23 18.6607C23 19.464 22.3488 20.1152 21.5455 20.1152L18.1484 20.1152L10.4545 20.1152Z"
        fill="#A0AEC0"
      />
      <path
        d="M10.4545 24.7676C9.65122 24.7676 9 24.1164 9 23.313C9 22.5097 9.65122 21.8585 10.4545 21.8585L16.8415 21.8585L19.5455 21.8585C20.3488 21.8585 21 22.5097 21 23.313C21 24.1164 20.3488 24.7676 19.5455 24.7676L16.8415 24.7676L10.4545 24.7676Z"
        fill="#A0AEC0"
      />
      <path
        d="M10.4545 15.5137C9.65122 15.5137 9 14.8624 9 14.0591C9 13.2558 9.65122 12.6046 10.4545 12.6046L16.8415 12.6046L19.5455 12.6046C20.3488 12.6046 21 13.2558 21 14.0591C21 14.8624 20.3488 15.5137 19.5455 15.5137L16.8415 15.5137L10.4545 15.5137Z"
        fill="#A0AEC0"
      />
      <path
        d="M10.4545 10.9092C9.65122 10.9092 9 10.258 9 9.45463C9 8.65131 9.65122 8.00009 10.4545 8.00009L18.1484 8.00009L21.5455 8.00009C22.3488 8.00009 23 8.65131 23 9.45463C23 10.258 22.3488 10.9092 21.5455 10.9092L18.1484 10.9092L10.4545 10.9092Z"
        fill="#A0AEC0"
      />
    </>
  ),
});

export const SuccessIcon = createIcon({
  displayName: "SuccessIcon",
  viewBox: "0 0 32 32",
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#C5F0C4" />
      <path
        d="M8.02852 17.1877C7.46048 16.6197 7.46048 15.6987 8.02852 15.1307C8.59655 14.5626 9.51752 14.5626 10.0856 15.1307L14.7982 19.8433C15.3662 20.4113 15.3662 21.3323 14.7982 21.9003C14.2301 22.4683 13.3092 22.4683 12.7411 21.9003L8.02852 17.1877Z"
        fill="#1F805D"
      />
      <path
        d="M14.7995 21.9002C14.2315 22.4682 13.3105 22.4682 12.7425 21.9002C12.1744 21.3322 12.1744 20.4112 12.7425 19.8432L19.4716 13.114L22.5572 10.0284C23.1252 9.46041 24.0462 9.46041 24.6142 10.0284C25.1823 10.5965 25.1823 11.5174 24.6142 12.0855L21.5287 15.171L14.7995 21.9002Z"
        fill="#1F805D"
      />
    </>
  ),
});

export const LoadingIcon = createIcon({
  displayName: "LoadingIcon",
  viewBox: "0 0 32 32",
  path: (
    <>
      <circle opacity="0.5" cx="16" cy="16" r="16" fill="#FBD38D" />
      <path
        d="M11.093 7.86305C11.6527 7.30334 12.5602 7.30334 13.1199 7.86305C13.6796 8.42276 13.6796 9.33023 13.1199 9.88993L10.0551 12.9547C9.49537 13.5145 8.5879 13.5145 8.02819 12.9547C7.46849 12.395 7.46849 11.4876 8.02819 10.9279L11.093 7.86305Z"
        fill="#ED8936"
      />
      <path
        d="M13.1201 14.3989C13.6786 14.9574 13.6786 15.8629 13.1201 16.4214C12.5615 16.98 11.656 16.98 11.0975 16.4214L7.82283 13.1468C7.26431 12.5882 7.26431 11.6827 7.82283 11.1242C8.38134 10.5657 9.28688 10.5657 9.84539 11.1242L13.1201 14.3989Z"
        fill="#ED8936"
      />
      <path
        d="M9.32954 13.6543C8.52622 13.6543 7.875 13.0031 7.875 12.1998C7.875 11.3964 8.52622 10.7452 9.32955 10.7452L16.2392 10.7452L19.2205 10.7452C20.0238 10.7452 20.675 11.3964 20.675 12.1998C20.675 13.0031 20.0238 13.6543 19.2205 13.6543L16.2392 13.6543L9.32954 13.6543Z"
        fill="#ED8936"
      />
      <path
        d="M20.9119 24.1369C20.3522 24.6967 19.4447 24.6967 18.885 24.1369C18.3253 23.5772 18.3253 22.6698 18.885 22.1101L21.9498 19.0453C22.5095 18.4855 23.417 18.4855 23.9767 19.0453C24.5364 19.605 24.5364 20.5124 23.9767 21.0721L20.9119 24.1369Z"
        fill="#ED8936"
      />
      <path
        d="M18.8848 17.6021C18.3263 17.0436 18.3263 16.1381 18.8848 15.5795C19.4433 15.021 20.3489 15.021 20.9074 15.5795L24.1821 18.8542C24.7406 19.4127 24.7406 20.3183 24.1821 20.8768C23.6235 21.4353 22.718 21.4353 22.1595 20.8768L18.8848 17.6021Z"
        fill="#ED8936"
      />
      <path
        d="M22.6753 18.3477C23.4787 18.3477 24.1299 18.9989 24.1299 19.8022C24.1299 20.6055 23.4787 21.2567 22.6753 21.2567L15.7656 21.2567L12.7844 21.2567C11.9811 21.2567 11.3299 20.6055 11.3299 19.8022C11.3299 18.9989 11.9811 18.3477 12.7844 18.3477L15.7656 18.3477L22.6753 18.3477Z"
        fill="#ED8936"
      />
    </>
  ),
});

export const StatusGreen = createIcon({
  displayName: "StatusGreen",
  viewBox: "0 0 32 32",
  path: (
    <>
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
        fill="#37C770"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#E2F8E2"
      />
    </>
  ),
});

export const StatusRed = createIcon({
  displayName: "StatusRed",
  viewBox: "0 0 32 32",
  path: (
    <>
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
        fill="#EA5858"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#FCEFEF"
      />
    </>
  ),
});

export const LockIcon = createIcon({
  displayName: "LockIcon",
  viewBox: "0 0 32 32",
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#CBD5E0" fillOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9233 23C10.8971 22.9921 10.8716 22.9847 10.8466 22.9774C10.4696 22.8671 10.233 22.7978 10.0003 22.0555V13.0828C9.9979 12.6132 10.0005 12.6108 10.4618 12.6108C10.9231 12.6108 11.4824 12.6115 11.4824 12.6115V10.2502C11.5 8.5 12.5 7.5 13 7C14.314 6.124 14.8293 6.08678 15.6367 6.02847C15.7509 6.02021 15.8711 6.01154 16.0001 6C17.6164 6.16903 17.9517 6.39509 18.533 6.78706C18.6068 6.83679 18.6845 6.8892 18.7693 6.9445C20.063 8.09654 20.5 9 20.5 10.2502V12.6115C20.5 12.6115 21.1373 12.6102 21.5687 12.6108C21.778 12.6111 21.8857 12.6113 21.9412 12.6652C22 12.7224 22 12.8402 22 13.0828V22.0555C21.9089 22.6731 21.722 22.8867 21.0769 23H10.9233ZM16.9232 20.1674L16.8311 17.8062C17.6369 16.9817 17.4082 16.0103 16.5 15.5C16.1536 15.3057 15.5 15.5 15.5 15.5C14.6653 15.9946 14.3917 16.9906 15.1888 17.8062L15.0771 20.1674H16.9232ZM19.2308 12.6115H12.7694V10.2502C12.9052 9.34596 13.1377 8.98079 13.6925 8.36124C14.589 7.53758 15.0771 7.41674 16.0001 7.41674C16.9232 7.41674 17.4819 7.66401 18.3078 8.36124C18.9422 9.06639 19.1428 9.50128 19.2308 10.2502V12.6115Z"
        fill="#A0AEC0"
      />
    </>
  ),
});
