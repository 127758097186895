// ====================================================================================
// Signup page, gets the invitation url and email for query params
// ====================================================================================
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { useSearchParams } from "react-router-dom";
import { signUp } from "@store";
import { useEnterKey } from "@hooks";
import {
  Button,
  Input,
  FormLabel,
  Text,
  Flex,
  Link,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { AuthHeader } from "./AuthHeader";

/**
 * Sign Up Component
 */
export const SignUp: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.auth.signUpLoading);
  const error = useSelector((state: RootState) => state.auth.signUpError);
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("invite");
  const inviteEmail = searchParams.get("email");
  const [email, setEmail] = useState<string>(inviteEmail || "");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  useEnterKey(() => onSubmit());

  /**
   * Handles submit
   */
  const onSubmit = () => {
    if (inviteCode && email !== "" && termsAccepted)
      dispatch(signUp({ email, inviteCode }));
  };

  return (
    <>
      <AuthHeader title="Sign Up" />
      <Text mb={12}>Create a new account to start cloning your voice.</Text>

      <FormControl mb={12} isInvalid={error !== ""}>
        <FormLabel htmlFor="email">Email address</FormLabel>
        <Input
          placeholder="example@email.com"
          id="email"
          size="lg"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Flex mb={8}>
        <input
          name="privacyTerms"
          type="checkbox"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
          style={{ marginRight: "6px" }}
          data-testid="terms-input"
        />
        <Text>
          I agree to the&nbsp;
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.aflorithmic.ai/terms-of-use"
          >
            Terms and conditions
          </Link>
        </Text>
      </Flex>

      <Flex justify="center">
        <Button
          onClick={() => onSubmit()}
          isLoading={isLoading}
          isDisabled={!termsAccepted || email === "" || inviteCode === null}
        >
          Sign Up
        </Button>
      </Flex>
      <Text textAlign="center" fontSize="sm" mt={8}>
        For the best results, please use Google Chrome
      </Text>
    </>
  );
};
