import { useSelector } from "react-redux";
import { RootState } from "@store";

/**
 * Access to user state
 */
export const useUserState = () => {
  const totalAesFeedback = useSelector(
    (state: RootState) => state.user.totalAesFeedback
  );

  const hasLoadedUserData = useSelector(
    (state: RootState) => state.user.hasLoadedUserData
  );

  return {
    totalAesFeedback,
    hasLoadedUserData,
  };
};
