// ====================================================================================
// Hook for display keyboard hints when the record page is loaded
// ====================================================================================
import { useEffect, useState } from "react";
import { Box, Kbd, Text, useToast } from "@chakra-ui/react";

export const useKeyboardHint = () => {
  const toast = useToast();
  const [shown, setShown] = useState<boolean>(false);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = () => {
    if (shown) return;
    setShown(true);
    toast({
      position: "top",

      render: () => (
        <Box color="black" p={3} bg="blue.100" borderRadius="6px">
          <Text>
            Use <Kbd>space</Kbd> to start and stop the recorder.
          </Text>
          <Text>
            Use <Kbd>delete</Kbd> to delete your last utterance.
          </Text>
        </Box>
      ),
    });
  };
};
