import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Box } from "@chakra-ui/react";

// this is an absolute PAIN
// emotion wont pass props down, should forward props wont work
// causes react errors
export const MenuStyled = styled(Box)`
  position: absolute;
  left: -300px;
  width: 300px;
  height: ${(props) => (props.$hasBottomBar ? "calc(100vh - 75px)" : "100vh")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  z-index: 1001;
  transition: 0.5s ease;
  ${(props) => props.$isOpen && "transform: translateX(300px)"};

  /* Make the menu full screen if lower than 768px */
  @media only screen and (max-width: 768px) {
    left: -768px;
    width: 100%;
    ${(props) => props.$isOpen && "transform: translateX(768px)"};
  }
`;

MenuStyled.defaultProps = {
  _dark: {
    bg: "menu.bg-dark",
  },
  _light: {
    bg: "menu.bg-light",
  },
};

/**
 * Logo Button to Open Menu
 */
export const MenuThumb = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px;
  border-radius: 50%;
  transition: 0.5s ease;
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
  }
  &:hover {
    transform: scale(1.3);
  }
`;

/**
 * Overlay to darken the rest of the screen when the menu is open
 */
export const MenuOverlay = styled(Box)`
  position: absolute;
  height: ${(props) => (props.$hasBottomBar ? "calc(100vh - 75px)" : "100vh")};
  width: 100%;
  z-index: 1000;
`;

MenuOverlay.defaultProps = {
  _dark: {
    bg: "menu.overlay-dark",
  },
  _light: {
    bg: "menu.overlay-light",
  },
};

/**
 * Logo in the Menu
 */
export const MenuLogo = styled.div`
  display: grid;
  place-items: center;
  margin: 16px 0;
  svg {
    width: 70px;
    height: 70px;
  }
`;

/**
 * Menu Item NavLink
 */
export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: 8px 0;
  text-decoration: none;
  font-weight: 500;
  color: inherit;
  padding: 8px 16px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s ease;
  border-radius: 6px;
  &:hover {
    background-color: var(--chakra-colors-blue-100);
  }
`;
