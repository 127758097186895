// ====================================================================================
// Redux reducer for the managing verticals
// ====================================================================================

import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Vertical } from "@types";
import { handleError } from "@utils";
import { VoiceClonerAPI } from "@api";
import { prepareUtterances } from "./recorderSlice";
import { VerticalUtterance } from "@hooks";
import { Config } from "@config";
import { RootState } from "./store";

export interface VerticalsState {
  recordingDataLoading: boolean;
  recordingDataError: string;
  verticals: Vertical[];
  total: number;
  count: number;
  fileUploadLoading: boolean;
  fileUploadError: string;
}

const initialState: VerticalsState = {
  recordingDataLoading: false,
  recordingDataError: "",
  verticals: [],
  total: 0,
  count: 0,
  fileUploadLoading: false,
  fileUploadError: "",
};

/*===================================================================*/
/* Async Thunks
/*===================================================================*/

/**
 * Gets the vertical data
 */
export const getVerticalData = createAsyncThunk(
  "getVerticalData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await VoiceClonerAPI.getAllVerticals();
      if (res) return res;
      return rejectWithValue("No data");
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Gets the vertical data
 */
export const getNextVerticalData = createAsyncThunk(
  "getNextVerticalData",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await VoiceClonerAPI.getAllVerticals();
      if (res) {
        dispatch(updateVerticals(res));
        const utterancesJoined: VerticalUtterance[] = [];
        res.forEach((vertical) => {
          vertical.utterancesToRecord.forEach((item) =>
            utterancesJoined.push({ ...item, vertical: vertical.verticalId })
          );
        });
        dispatch(
          prepareUtterances({
            utterances: utterancesJoined.slice(0, Config.utterancesPerStage),
          })
        );
      }
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Handles file upload
 */
export const uploadCompleted = createAsyncThunk(
  "uploadCompleted",
  async (
    {
      audioBlob,
      textFileId,
      verticalId,
    }: {
      audioBlob: Blob;
      textFileId: string;
      verticalId: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      // Get the audio device name to attach as metadata
      const state = getState() as RootState;
      await VoiceClonerAPI.uploadFile(
        verticalId,
        textFileId,
        audioBlob,
        state.recorder.audioDeviceName
      );
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

/**
 * Data Reducer
 */
export const verticalsSlice = createSlice({
  name: "verticals",
  initialState,
  reducers: {
    // Sets countdown length
    updateVerticals: (state, action: PayloadAction<Vertical[]>) => {
      state.verticals = action.payload;
      state.total = action.payload.reduce(
        (prev: number, item: Vertical) => prev + item.summary.total,
        0
      );

      state.count = action.payload.reduce(
        (prev: number, item: Vertical) => prev + item.summary.count,
        0
      );
    },
  },
  extraReducers: (builder) => {
    // -------------------------------------------------------
    // getRecordingData Cases
    // -------------------------------------------------------
    builder.addCase(getVerticalData.pending, (state) => {
      state.recordingDataLoading = true;
      state.recordingDataError = "";
    });
    builder.addCase(
      getVerticalData.fulfilled,
      (state, action: PayloadAction<Vertical[]>) => {
        state.recordingDataLoading = false;
        state.recordingDataError = "";
        state.verticals = action.payload;

        state.total = action.payload.reduce(
          (prev: number, item: Vertical) => prev + item.summary.total,
          0
        );

        state.count = action.payload.reduce(
          (prev: number, item: Vertical) => prev + item.summary.count,
          0
        );
      }
    );
    builder.addCase(getVerticalData.rejected, (state, action) => {
      state.recordingDataLoading = false;
      // @ts-expect-error Always will be a string
      state.recordingDataError = action.payload;
    });

    // Next vertical data
    builder.addCase(getNextVerticalData.pending, (state) => {
      state.recordingDataLoading = true;
      state.recordingDataError = "";
    });
    builder.addCase(getNextVerticalData.fulfilled, (state) => {
      state.recordingDataLoading = false;
      state.recordingDataError = "";
    });
    builder.addCase(getNextVerticalData.rejected, (state, action) => {
      state.recordingDataLoading = false;
      // @ts-expect-error Always will be a string
      state.recordingDataError = action.payload;
    });

    // -------------------------------------------------------
    // fileUpload Cases
    // -------------------------------------------------------
    builder.addCase(uploadCompleted.pending, (state) => {
      state.fileUploadLoading = true;
      state.fileUploadError = "";
    });
    builder.addCase(uploadCompleted.fulfilled, (state) => {
      state.fileUploadLoading = false;
      state.fileUploadError = "";
    });
    builder.addCase(uploadCompleted.rejected, (state, action) => {
      state.fileUploadLoading = false;
      // @ts-expect-error Always will be a string
      state.fileUploadError = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateVerticals } = verticalsSlice.actions;
export default verticalsSlice.reducer;
