// ====================================================================================
// Redux reducer for the user data
// ====================================================================================

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { handleError } from "@utils";
import { VoiceClonerAPI } from "@api";
import { AESTotalFeedback, UserData, UserMetaData } from "@types";
import { RootState } from "./store";

export interface UserState {
  hasLoadedUserData: boolean;
  userdataLoading: boolean;
  userdataError: string;
  name: string;
  gender: string;
  availableVerticals: string[];
  selectedVerticals: string[];
  metadata: UserMetaData;
  totalAesFeedback: AESTotalFeedback;
}

const initialMetaData: UserMetaData = {
  testFlag: false,
};

const initialState: UserState = {
  hasLoadedUserData: false,
  userdataLoading: false,
  userdataError: "",
  name: "",
  gender: "",
  availableVerticals: [],
  selectedVerticals: [],
  metadata: initialMetaData,
  totalAesFeedback: {
    total: 0,
    passed: 0,
    passedBackgroundNoise: 0,
    passedInterrupted: 0,
    passedClipping: 0,
    passedReverb: 0,
    passedTextCoverage: 0,
    passedTooLoud: 0,
    passedTooQuiet: 0,
    passedValidAudio: 0,
  },
};

/*===================================================================*/
/* Async Thunks
/*===================================================================*/

/**
 * Gets the user data
 */
export const getUserData = createAsyncThunk(
  "getUserData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await VoiceClonerAPI.getUserData();
      if (res) return res;
      return rejectWithValue("No Data");
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Gets the user data
 */
export const updateMetadata = createAsyncThunk(
  "updateMetadata",
  async (meta: object, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    try {
      if (!state.user.hasLoadedUserData) {
        throw new Error("Load metadata before saving");
      }
      const res = await VoiceClonerAPI.setUserData({
        metadata: { ...state.user.metadata, ...meta },
      });
      if (res) return res;
      return rejectWithValue("No data");
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Gets the user data
 */
export const getAESFeedback = createAsyncThunk(
  "getAESFeedback",
  async (sub: string, { rejectWithValue }) => {
    try {
      const res = await VoiceClonerAPI.getTotalAESFeedback(sub);
      if (res) return res;
      return rejectWithValue("No Data");
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

/**
 * Data Reducer
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Moves an utterance from upcoming to completed
    // changeMetadata: (state, action: PayloadAction<any>) => {
    //   state.metadata = { ...state.metadata, ...action.payload };
    // },
  },
  extraReducers: (builder) => {
    // -------------------------------------------------------
    // getUserData Cases
    // -------------------------------------------------------
    builder.addCase(getUserData.pending, (state) => {
      state.userdataLoading = true;
      state.userdataError = "";
    });
    builder.addCase(
      getUserData.fulfilled,
      (state, action: PayloadAction<UserData>) => {
        state.userdataLoading = false;
        state.userdataError = "";
        state.hasLoadedUserData = true;
        if (action.payload.name) state.name = action.payload.name;
        if (action.payload.gender) state.gender = action.payload.gender;
        if (action.payload.availableVerticals)
          state.availableVerticals = action.payload.availableVerticals;
        if (action.payload.selectedVerticals)
          state.availableVerticals = action.payload.selectedVerticals;

        // Make sure we don't overwrite any default values that don't exist on the user yet
        if (action.payload.metadata) {
          state.metadata = { ...state.metadata, ...action.payload.metadata };
        }
      }
    );
    builder.addCase(getUserData.rejected, (state, action) => {
      state.userdataLoading = false;
      // @ts-expect-error Always will be a string
      state.userdataError = action.payload;
    });

    // -------------------------------------------------------
    // updateMetadata Cases
    // -------------------------------------------------------
    builder.addCase(updateMetadata.pending, (state) => {
      state.userdataLoading = true;
      state.userdataError = "";
    });
    builder.addCase(
      updateMetadata.fulfilled,
      (state, action: PayloadAction<UserData>) => {
        if (action.payload.metadata)
          state.metadata = { ...state.metadata, ...action.payload.metadata };
        state.userdataLoading = false;
        state.userdataError = "";
      }
    );
    builder.addCase(updateMetadata.rejected, (state, action) => {
      state.userdataLoading = false;
      // @ts-expect-error Always will be a string
      state.userdataError = action.payload;
    });

    // -------------------------------------------------------
    // getAESFeedback Cases
    // -------------------------------------------------------
    builder.addCase(
      getAESFeedback.fulfilled,
      (state, action: PayloadAction<AESTotalFeedback>) => {
        state.totalAesFeedback = action.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {} = userSlice.actions;

export default userSlice.reducer;
