import React from "react";
import {
  Text,
  Box,
  Grid,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { RecordButton } from "@components";
import { ICalibrationResults } from "../utils/CalculateResults";
import { RecorderState } from "@types";
import { ResultCard } from "./ResultCard";

type Props = {
  result: ICalibrationResults | undefined;
  recorderState: RecorderState;
  nextOnClick: () => void;
  recordOnClick: () => void;
  count: number;
};

/**
 * Checks the users background noise
 * @param result
 * @param recorderState
 * @param nextOnClick
 * @param recordOnClick
 * @param count
 */
export const NoiseCheck: React.FC<Props> = ({
  result,
  recorderState,
  nextOnClick,
  recordOnClick,
  count,
}) => {
  return (
    <>
      <Text mb={8}>
        In order to optimise your voice cloning process, we need to verify that
        the amount of background noise in your environment is optimal for our
        artificial intelligence model.
      </Text>
      <Grid templateColumns={["1fr", "1fr", "1fr 1fr"]} gap={8}>
        <Box borderWidth="1px" p={4} borderRadius="6px">
          <Text mb={8} fontWeight="semibold">
            Background Noise Check
          </Text>
          <Alert status="info" mb={8} mt={4} bg="blue.50">
            <AlertIcon />
            <AlertDescription>
              Press the microphone button below and remain silent
            </AlertDescription>
          </Alert>
          <Grid placeItems="center">
            <RecordButton
              recorderState={recorderState}
              onClick={recordOnClick}
              isCountdown={recorderState === "RECORDING"}
              count={count}
            />
          </Grid>
        </Box>

        {/* Result Cards */}
        <ResultCard
          type={result?.result}
          retryOnClick={recordOnClick}
          nextOnClick={nextOnClick}
        >
          {result?.result === "LOUD" && (
            <Text>
              Your environment seems a little too noisy, try reduce the noise or
              move to another room and try again.
            </Text>
          )}
          {result?.result === "PASS" && <Text>Looks Good!</Text>}
          {result === undefined && (
            <Text color="gray.400">Waiting for Results</Text>
          )}
        </ResultCard>
      </Grid>
    </>
  );
};
