import React from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { hideHelpModal } from "@store";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

/**
 * Displays the help modal
 */
export const HelpModal: React.FC = () => {
  const isOpen = useSelector((state: RootState) => state.app.showHelpModal);
  const heading = useSelector((state: RootState) => state.app.helpModalHeading);
  const content = useSelector((state: RootState) => state.app.helpModalContent);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Modal isOpen={isOpen} onClose={() => dispatch(hideHelpModal())}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => dispatch(hideHelpModal())}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
