// ====================================================================================
// Hook for detecting silence in an audio stream
// ====================================================================================
/* eslint-disable no-console */
import { useContext, useEffect } from "react";
import { dbFromFloat } from "@utils";
import { useRecorderState } from "@hooks";
import { RecorderContext } from "@context";

// Reference to the animation frame
let frameId: number;
let animateTimeout: ReturnType<typeof setTimeout>;

const SILENCE_BUFFER_FRAMES = 75;
const SILENCE_FPS = 60;
const Config = { isProduction: false };

/**
 * useSilenceDetection
 * Executes a callback if the audio level is below the threshold for x seconds
 * @param {*} onSilenceDetected Called after 2 seconds of silence
 */
export const useSilenceDetection = (onSilenceDetected: () => void) => {
  const {
    recorderState,
    silenceDetectorActive,
    silenceDetectorFirstSoundThreshold,
    silenceDetectorSilenceThreshold,
  } = useRecorderState();
  const { recorderRef } = useContext(RecorderContext);

  const isActive = recorderState === "RECORDING" && silenceDetectorActive;

  /**
   * Main Loop for detecting silence
   */
  useEffect(() => {
    // If inactive or no access to the audio context
    if (!isActive || !recorderRef.current?.analyser) {
      cancelAnimationFrame(frameId);
      clearTimeout(animateTimeout);
      return;
    }

    // Create buffers to store the data
    const bufferLength = recorderRef.current.analyser.fftSize;
    const buffer = new Float32Array(bufferLength);
    let previousRMS = new Array(SILENCE_BUFFER_FRAMES).fill(1);

    let firstSoundDetected = false;

    // Event Loop
    const loop = () => {
      // If inactive or no access to the audio context
      if (!isActive || !recorderRef.current?.analyser) {
        cancelAnimationFrame(frameId);
        clearTimeout(animateTimeout);
        return;
      }

      // Get the data from the analyser
      recorderRef.current.analyser.getFloatTimeDomainData(buffer);

      // Calculate rms of the buffer
      let rms = 0;
      for (let i = 0; i < buffer.length; i += 1) {
        rms += buffer[i] * buffer[i];
      }
      rms /= buffer.length;
      rms = Math.sqrt(rms);

      const rmsDb = dbFromFloat(rms);

      // Detect the first sound
      if (rmsDb > silenceDetectorFirstSoundThreshold && !firstSoundDetected) {
        firstSoundDetected = true;
        previousRMS = new Array(SILENCE_BUFFER_FRAMES).fill(1);
        if (!Config.isProduction) {
          console.log("Silence Detector: First sound detected");
        }
      }

      // Detect silence (once the first sound has been detected)
      if (firstSoundDetected) {
        // Push the current value into the array and take out the oldest value
        previousRMS.push(rms);
        previousRMS.shift();

        // Get average of the last 2 seconds of rms values
        let avg = 0;
        for (let x = 0; x < previousRMS.length; x += 1) {
          avg += previousRMS[x];
        }
        avg /= previousRMS.length;

        const avgDb = dbFromFloat(avg);

        // If the average is below the threshold, trigger the silence callback
        if (avgDb < silenceDetectorSilenceThreshold) {
          if (!Config.isProduction) {
            console.log("Silence Detector: Silence Detected");
          }
          cancelAnimationFrame(frameId);
          clearTimeout(animateTimeout);
          onSilenceDetected();
        }
      }

      // Calls the loop 60 times per second if Active
      if (isActive) {
        animateTimeout = setTimeout(() => {
          frameId = requestAnimationFrame(loop);
        }, 1000 / SILENCE_FPS);
      } else {
        // Clear everything if inactive
        clearTimeout(animateTimeout);
        cancelAnimationFrame(frameId);
      }
    };

    // Start the animation loop
    if (isActive) frameId = requestAnimationFrame(loop);

    // Cancel the animation loop when unmounted
    // eslint-disable-next-line consistent-return
    return () => {
      cancelAnimationFrame(frameId);
    };
    // eslint-disable-next-line
  }, [isActive]);
};
