// Components
export const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
      borderRadius: "md",
    },
    defaultProps: {
      colorScheme: "secondary",
      size: "lg",
    },
  },
  Heading: {
    defaultProps: {
      size: "lg",
    },
    baseStyle: {
      // color: "secondary.600",
      // fontFamily: "Roboto",
    },
  },
  Switch: {
    defaultProps: {
      size: "md",
      colorScheme: "secondary",
    },
  },
  Alert: {
    baseStyle: {
      borderRadius: "6px",
    },
    defaultProps: {
      borderRadius: "6px",
    },
  },
  CircularProgress: {
    defaultProps: {
      size: "md",
      trackColor: "green",
    },
    baseStyle: {
      trackColor: "green",
    },
  },
  Tooltip: {
    baseStyle: {
      bg: "white",
      color: "black",
      p: 4,
      borderRadius: "6px",
    },
  },
};
