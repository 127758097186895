// ====================================================================================
// Hook for retaking an utterance
// ====================================================================================
import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  AppDispatch,
  retakeUtterance,
  setAutoStartCount,
  setState,
} from "@store";
import { Utterance } from "@types";
import { scrollToUtterance, stopRecording } from "@utils";
import { useRecorderState } from "@hooks";
import { RecorderContext } from "@context";

/**
 * Handles retaking an utterance
 */
export const useRetake = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { completed, recorderState, countdownLength } = useRecorderState();
  const { audioStreamRef, recorderRef } = useContext(RecorderContext);

  /**
   * Called when retake button is pressed, moves an utterance from completed to items
   * @param id of the Utterance
   */
  const retakeOnClick = (id: string) => {
    // Reset the countdown
    dispatch(setAutoStartCount(countdownLength));

    // Stop the recording there's a weird async thing here
    if (recorderState === "RECORDING") {
      stopRecording(audioStreamRef, recorderRef);
      dispatch(setState("IDLE"));
      return;
    }

    // Find the item by id
    const retakeItem = completed.find((item: Utterance) => item.id === id);
    if (!retakeItem) return;

    // Remove the Audio Blob from the browser to save memory
    if (retakeItem.audio) URL.revokeObjectURL(retakeItem.audio);

    // Move the utterance to the top of the upcoming stack
    dispatch(
      retakeUtterance({
        ...retakeItem,
        status: undefined,
        audio: undefined,
        errors: undefined,
        uploaded: undefined,
        waveform: undefined,
      })
    );

    scrollToUtterance();
  };

  return {
    retakeOnClick,
  };
};
