// ====================================================================================
// Bottom bar controls - Handsfree mode toggle
// ====================================================================================
import {
  Flex,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { toggleAutoStart, toggleIsPaused } from "@store";
import { scrollToUtterance } from "@utils";
import { useRecorderState } from "@hooks";
import { StatusGreen, StatusRed } from "@assets";

/**
 * Bottom Bar Quick Controls
 */
export function Controls() {
  const autostart = useSelector((state: RootState) => state.recorder.autoStart);
  const isPaused = useSelector((state: RootState) => state.recorder.isPaused);
  const upcoming = useSelector((state: RootState) => state.recorder.upcoming);
  const { deviceQuality } = useRecorderState();
  const hasRunCalibration = useSelector(
    (state: RootState) => state.recorder.hasRunCalibration
  );
  const silenceDetector = useSelector(
    (state: RootState) => state.recorder.silenceDetectorActive
  );

  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  return (
    <Flex style={{ gap: "16px" }} ml={{ base: 4, md: 8 }}>
      {deviceQuality === 0 && (
        <Tooltip label="Device Quality: Poor">
          <StatusRed boxSize={8} />
        </Tooltip>
      )}
      {deviceQuality === 1 && (
        <Tooltip label="Device Quality: Good">
          <StatusGreen boxSize={8} />
        </Tooltip>
      )}

      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="hands-free" mb="0" textAlign="center">
          Hands Free {isPaused && "PAUSED"}
        </FormLabel>
        <Switch
          id="hands-free"
          value="true"
          isChecked={autostart}
          isDisabled={!silenceDetector || !hasRunCalibration}
          onChange={() => {
            if (upcoming.length === 0 && !autostart) {
              toast({
                title: "Hands Free Mode",
                description: "No utterances remaining!",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }
            if (autostart) {
              toast({
                title: "Hands Free Mode",
                description: "Deactivated",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
              if (isPaused) dispatch(toggleIsPaused());
            } else {
              toast({
                title: "Hands Free Mode",
                description: "Activated",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });

              if (isPaused) dispatch(toggleIsPaused());
            }
            dispatch(toggleAutoStart());
            scrollToUtterance();
          }}
        />
      </FormControl>
    </Flex>
  );
}
