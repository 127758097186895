import React from "react";
import { IconButton, Button } from "@chakra-ui/react";
import { VoiceCloningIcon, StopIcon } from "@assets";
import { RecorderState } from "@types";

type Props = {
  recorderState: RecorderState;
  onClick: () => void;
  isCountdown?: boolean;
  count?: number;
  isPaused?: boolean;
};

/**
 * Record button with visual elements for user feedback
 * @param recorderState Colors the button based on the recorders state
 * @param onClick Callback when clicked
 * @param isCountdown Shows the number for count instead of the icon
 * @param count Number to show inside the button
 * @param isPaused
 */
export const RecordButton: React.FC<Props> = ({
  recorderState,
  onClick,
  isCountdown = false,
  count = 3,
  isPaused = false,
}) => {
  // Calculate styles based on state
  const buttonSize = { base: "56px", md: "72px" };
  let color = "gray";
  if (recorderState === "RECORDING") color = "red";
  if (recorderState === "ARM") color = "orange";

  return (
    <>
      {/* For all states except when countdown is not paused*/}
      {(!isCountdown || (isCountdown && isPaused)) && (
        <IconButton
          aria-label="Record Utterance"
          isRound
          colorScheme={color}
          width={buttonSize}
          height={buttonSize}
          onClick={onClick}
          icon={
            isPaused || recorderState !== "RECORDING" ? (
              <VoiceCloningIcon width="28px" height="28px" />
            ) : (
              <StopIcon width="20px" height="20px" />
            )
          }
          data-testid="record-button"
        />
      )}
      {/* Button with text for the countdown */}
      {isCountdown && !isPaused && (
        <Button
          borderRadius="50%"
          colorScheme={color}
          width={buttonSize}
          height={buttonSize}
          fontSize={{ base: "20px", md: "28px" }}
          data-testid="record-button-countdown"
        >
          {count}
        </Button>
      )}
    </>
  );
};
