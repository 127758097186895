// ====================================================================================
// Draws the Waveform in the bottom bar
// ====================================================================================
import React, { useContext, useEffect } from "react";
import { RecorderContext } from "@context";
import { useAnimationFrame, useRecorderState } from "@hooks";
import { useAudioPlayback } from "../hooks";
import { dbFromFloat } from "@utils";
import { drawWaveform } from "../utils";
import { useColorModeValue, useToken } from "@chakra-ui/react";
import { WaveFormContainer } from "./Waveform.styled";

type Props = {
  width: number;
  height: number;
};

/**
 * Displays the audio waveform during recording
 * @param width
 * @param height
 * @constructor
 */
export const Waveform: React.FC<Props> = ({ width = 335, height = 100 }) => {
  const { recorderRef } = useContext(RecorderContext);
  const { recorderState } = useRecorderState();
  const recordWaveColor = useToken(
    "colors",
    useColorModeValue("waveformRecord.light", "waveformRecord.dark")
  );
  const isActive = recorderState === "RECORDING";
  const rec: number[] = new Array(128).fill(-100);
  useAudioPlayback();

  /**
   * Calculates the peak value, stores it in a stack with previous peaks and draws the waveform
   */
  const animate = () => {
    if (!isActive || !recorderRef.current?.analyser) return;

    // Create a new array buffer and fill it with the data from the analyser
    const bufferLength: number = recorderRef.current.analyser.fftSize;
    const buffer = new Float32Array(bufferLength);
    recorderRef.current.analyser.getFloatTimeDomainData(buffer);

    // Calculate peak values
    let maxSample = 0;
    for (let i = 0; i < buffer.length; i += 1) {
      const sample = Math.abs(buffer[i]);
      if (sample > maxSample) {
        maxSample = sample;
      }
    }

    // Remove last value in the stack and add the new one to the start
    rec.pop();
    rec.unshift(dbFromFloat(maxSample));

    draw(rec);
  };

  /**
   * Gets the context and draws the waveform
   * @param value
   */
  const draw = (values: number[]) => {
    const canvas = document.getElementById("waveform") as HTMLCanvasElement;
    canvas.style.width = "600px";
    canvas.style.height = "67px";
    const recCtx = canvas?.getContext("2d");
    if (recCtx) drawWaveform(recCtx, values, recordWaveColor);
  };

  // Call the animate hook
  useAnimationFrame(animate, isActive, 30);

  // Draw the meter on component load, otherwise the canvas will be empty
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => draw([]), []);

  return (
    <WaveFormContainer>
      <canvas
        id="waveform"
        width={width.toString()}
        height={height.toString()}
      />
    </WaveFormContainer>
  );
};
