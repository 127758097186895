// ====================================================================================
// ReviewPlayer - Component for the play audio button
// ====================================================================================
import { Flex, IconButton, Spinner } from "@chakra-ui/react";
import { FaPlay, FaStop } from "react-icons/fa";
import { ReviewUtterance } from "../hooks/useReview";

export const ReviewPlayer = (props: {
  playbackId: string;
  playbackLoading: boolean;
  utterance: ReviewUtterance;
  onClick: () => void;
}) => {
  return (
    <Flex direction="column" gap={2} justifyContent="center" ml={4}>
      <IconButton
        aria-label="Playback Utterance"
        icon={
          props.playbackId !== props.utterance.id ? (
            <FaPlay />
          ) : props.playbackLoading ? (
            <Spinner />
          ) : (
            <FaStop />
          )
        }
        colorScheme="gray"
        isRound
        onClick={() => props.onClick()}
      />
    </Flex>
  );
};
