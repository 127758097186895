// ====================================================================================
// Hook for controlling the global audio playback (state in redux)
// ====================================================================================
import { useDispatch } from "react-redux";
import { AppDispatch, setIsPlaying, setPlayback, setState } from "@store";
import { useRecorderState } from "@hooks";
import { stopRecording } from "@utils";
import { useContext } from "react";
import { RecorderContext } from "@context";

/**
 * Handles controlling playback of utterances
 */
export const usePlayback = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { playbackIsPlaying, playbackId, recorderState } = useRecorderState();
  const { audioStreamRef, recorderRef } = useContext(RecorderContext);

  /**
   * Starts audio playback
   */
  const play = (id: string) => {
    // Stop the recording there's a weird async thing here
    if (recorderState === "RECORDING") {
      stopRecording(audioStreamRef, recorderRef);
      dispatch(setState("IDLE"));
    }

    if (!playbackIsPlaying) {
      dispatch(setPlayback(id));
    } else {
      dispatch(setIsPlaying(false));
    }
  };

  return {
    play,
    playbackId,
    playbackIsPlaying,
  };
};
