// ====================================================================================
// Hook for hands free mode
// ====================================================================================
import { useDispatch } from "react-redux";
import type { AppDispatch } from "@store";
import { setAutoStartCount, setState, toggleIsPaused } from "@store";
import { scrollToUtterance } from "@utils";
import { useInterval, useRecorderState } from "@hooks";

/**
 * Controls the state of the recorder automatically if Hands Free is enabled.
 */
export const useHandsFree = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    recorderState,
    autoStart,
    upcoming,
    countdownLength,
    autoStartCount,
    isPaused,
  } = useRecorderState();

  /**
   * Runs the countdown
   */
  useInterval(
    () => {
      // Don't autostart and turn it off if there are no more utterances
      if (upcoming.length === 0) {
        if (autoStart) dispatch(toggleIsPaused());
        return;
      }

      // Start if at 1
      if (autoStartCount === 1) {
        scrollToUtterance();
        dispatch(setState("ARM"));
        dispatch(setAutoStartCount(countdownLength));
        return;
      }

      // Decrement by 1
      dispatch(setAutoStartCount(autoStartCount - 1));
    },
    1000,
    autoStart && recorderState === "IDLE" && !isPaused
  );
};
