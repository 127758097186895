// ====================================================================================
// Handles whats happening when the stage is finished
// ====================================================================================
import { useDispatch } from "react-redux";
import {
  AppDispatch,
  getNextVerticalData,
  retakeUtterance,
  toggleIsPaused,
} from "@store";
import { useRecorderState, useVerticalsState } from "@hooks";
import { scrollToUtterance } from "@utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

/**
 * Hook for handling the retake all / next stages sequence at the end of a stage
 */
export const useEndOfStage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { completed, upcoming, autoStart, isPaused, previousPage } =
    useRecorderState();
  const { utterancesLoading } = useVerticalsState();

  useEffect(() => {
    if (upcoming.length === 0 && completed.length === 0) {
      navigate(previousPage);
    }
  }, [completed, upcoming, navigate, previousPage]);

  /**
   * Moves failed utterances back to upcoming
   */
  function retakeFailed() {
    // We need to iterate over the array in reverse to keep the order of the utterances
    // Reverse modifies the original array, so we use slice to create a copy
    completed
      .slice(0)
      .reverse()
      .forEach((item) => {
        // Ignore if the item passed
        if (item.errors?.length === 0) return;
        if (item.status === "LOADING" || item.status === "PASS") return;

        // Remove old blobs
        if (item.audio) URL.revokeObjectURL(item.audio);

        // Move the utterance to the top of the upcoming stack
        dispatch(
          retakeUtterance({
            ...item,
            status: undefined,
            audio: undefined,
            errors: [],
          })
        );
      });
    if (autoStart && isPaused) dispatch(toggleIsPaused());
    scrollToUtterance();
  }

  /**
   * Loads the next utterances into the recorder
   */
  async function nextStage() {
    // Revoke my little blobs to prevent memory leaks
    completed.map((item) => {
      if (item.audio) URL.revokeObjectURL(item.audio);
    });

    if (previousPage !== "/") {
      navigate(previousPage);
    }

    await dispatch(getNextVerticalData());
    if (autoStart && isPaused) dispatch(toggleIsPaused());
    scrollToUtterance();
  }

  return {
    retakeFailed,
    next: nextStage,
    isLoading: utterancesLoading,
  };
};
