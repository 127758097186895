// ====================================================================================
// Hook which allows the use of keyboard controls (space / delete)
// ====================================================================================
import { useEventListener, useRecorderState } from "@hooks";
import { useRetake } from "./useRetake";
import { useTransportControl } from "./useTransportControl";
import { useEndOfStage } from "./useEndOfStage";

/**
 * Adds keyboard functionality to recording page
 */
export const useKeyboardControls = () => {
  const { completed, upcoming } = useRecorderState();
  const retake = useRetake();
  const endOfStage = useEndOfStage();
  const { playControl } = useTransportControl();

  /**
   * Handles the space bar
   */
  const handleSpace = () => {
    if (upcoming.length > 0) {
      setTimeout(() => playControl(), 50);
      return;
    }
    // If it's the end of the stage, retake failed or move to next stage
    if (
      completed.filter((item) => item.errors && item.errors.length > 0).length >
      0
    ) {
      endOfStage.retakeFailed();
    } else if (
      completed.filter((item) => item.status === "LOADING").length === 0
    ) {
      endOfStage.next();
    }
  };

  /**
   * Handles routing the key presses
   * @param event
   */
  // @ts-expect-error TODO find the type
  function keyHandler(event) {
    event.preventDefault();
    // Backspace - Delete last utterance
    if (["Backspace"].includes(String(event.key))) {
      retake.retakeOnClick(completed[completed.length - 1].id);
    }
    // Space - transport control with 50ms delay to help avoid keyboard clicks
    if ([" ", "Space"].includes(String(event.key))) {
      handleSpace();
    }
  }

  useEventListener("keydown", keyHandler);
};
