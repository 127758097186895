// ====================================================================================
// Settings page
// ====================================================================================
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { AppDispatch, RootState } from "@store";
import {
  toggleAutoStart,
  toggleSilenceDetector,
  setCountdownLength,
} from "@store";
import { Panel } from "@components";
import {
  Button,
  Flex,
  Switch,
  Text,
  useColorMode,
  NumberInput,
  NumberInputStepper,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from "@chakra-ui/react";

/**
 * Page which allows user to change app settings
 */
export const Settings: React.FC = () => {
  const autostart = useSelector((state: RootState) => state.recorder.autoStart);
  const silenceDetector = useSelector(
    (state: RootState) => state.recorder.silenceDetectorActive
  );
  const countdownLength = useSelector(
    (state: RootState) => state.recorder.countdownLength
  );
  const dispatch = useDispatch<AppDispatch>();
  const { toggleColorMode, colorMode } = useColorMode();
  const navigate = useNavigate();
  return (
    <Panel title="Settings" isSmall>
      <Flex direction="column" justifyContent="space-between">
        <Box height="100%">
          <Flex justify="space-between" mb={6}>
            <Text>Dark Mode</Text>
            <Switch
              isChecked={colorMode === "dark"}
              onChange={() => toggleColorMode()}
              disabled={true}
            />
          </Flex>
          <Flex justify="space-between" mb={6}>
            <Text>Auto stop on silence</Text>
            <Switch
              isChecked={silenceDetector}
              onChange={() => dispatch(toggleSilenceDetector())}
            />
          </Flex>
          <Flex justify="space-between" mb={6}>
            <Text>Hands Free Mode</Text>
            <Switch
              isChecked={autostart}
              onChange={() => dispatch(toggleAutoStart())}
              isDisabled={!silenceDetector}
            />
          </Flex>
          <Flex justify="space-between" align="center" mb={6}>
            <Text>Countdown length (seconds)</Text>
            <NumberInput
              value={countdownLength}
              keepWithinRange={true}
              clampValueOnBlur={true}
              min={1}
              max={10}
              maxWidth="100px"
              onChange={(e) => {
                if (e !== "") {
                  dispatch(setCountdownLength(parseInt(e)));
                }
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
        </Box>
        <Flex justify="center" mt={12}>
          <Button onClick={() => navigate("/")}>Return</Button>
        </Flex>
      </Flex>
    </Panel>
  );
};
