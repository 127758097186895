// ====================================================================================
// Calculates the colors for the card state
// ====================================================================================
import { useColorModeValue, useToken } from "@chakra-ui/react";
import { RecorderState } from "@types";

/**
 * useColorCalc
 * @param current
 * @param recorderState
 * @param status
 * @returns
 */
export const useColorCalc = (
  current: boolean,
  recorderState: RecorderState,
  status: string | undefined
) => {
  let back = ["gray.50", "gray.900"];
  let out = ["", ""];
  if (current) back = ["yellow.50", "blue.600"];
  if (status === "PASS") back = ["green.50", "green.700"];
  if (status === "FAIL") back = ["red.50", "red.700"];
  if (status === "LOADING") back = ["orange.50", "orange.700"];
  if (recorderState === "RECORDING") out = ["red.200", "red.700"];
  if (recorderState === "ARM") out = ["orange.200", "orange.700"];

  // Can't put hooks in control statements
  const bg = useColorModeValue(back[0], back[1]);
  const outlineColor = useColorModeValue(out[0], out[1]);
  const color = useToken("colors", outlineColor);
  return {
    bg,
    outlineColor,
    outline:
      current &&
      color &&
      (recorderState === "RECORDING" || recorderState === "ARM")
        ? `4px ${color} solid`
        : "",
  };
};
