import { theme as baseTheme } from "@chakra-ui/react";
// Theme
// Use https://smart-swatch.netlify.app/ to generate palettes from your base colour
export const colors = {
  primary: {
    50: "#e4e9ff",
    100: "#b4beff",
    200: "#8493fa",
    300: "#5468f7",
    400: "#263df4",
    500: "#1124db",
    600: "#0a1bab",
    700: "#05147b",
    800: "#010c4c",
    900: "#00041e",
  },
  secondary: {
    50: "#e0f4ff",
    100: "#badaf7",
    200: "#92c1ec",
    300: "#68a8e3",
    400: "#408fda",
    500: "#2876c1",
    600: "#1c5c97",
    700: "#11426d",
    800: "#042744",
    900: "#000e1c",
  },

  // blue: {
  //   50: "#e4e9ff",
  //   100: "#b4beff",
  //   200: "#8493fa",
  //   300: "#5468f7",
  //   400: "#263df4",
  //   500: "#1124db",
  //   600: "#0a1bab",
  //   700: "#05147b",
  //   800: "#010c4c",
  //   900: "#00041e",
  // },

  progress: {
    900: "#e0fcf4",
    800: "#c0eedf",
    700: "#9de0cb",
    600: "#77d2b7",
    500: "#9de0cb", // light mode color
    400: "#3aac88",
    300: "#2a866a",
    200: "#2a866a", // dark mode color
    100: "#093a2c",
    50: "#00160d",
  },
  waveform: {
    light: baseTheme.colors.green["300"],
    dark: baseTheme.colors.teal["500"],
  },
  waveformRecord: {
    light: baseTheme.colors.green["400"],
    dark: baseTheme.colors.green["300"],
  },
  meterBg: {
    light: baseTheme.colors.gray["200"],
    dark: baseTheme.colors.whiteAlpha["400"],
  },
  panel: {
    light: baseTheme.colors.gray["50"],
    dark: baseTheme.colors.gray["700"],
  },
  menu: {
    "bg-light": baseTheme.colors.gray["50"],
    "bg-dark": baseTheme.colors.gray["700"],
    // "overlay-light": "rgba(5, 20, 125, 0.4)",
    "overlay-light": "rgba(180,180,180,0.4)",
    "overlay-dark": "rgba(28,28,28,0.4)",
    "progress-light": baseTheme.colors.green["300"],
    "progress-dark": baseTheme.colors.green["500"],
    "hover-light": baseTheme.colors.blue["50"],
    "hover-dark": baseTheme.colors.blue["700"],
  },
  card: {
    "pass-light": baseTheme.colors.green["50"],
    "pass-dark": baseTheme.colors.green["700"],
    "fail-light": baseTheme.colors.red["50"],
    "fail-dark": baseTheme.colors.red["700"],
    "current-light": baseTheme.colors.yellow["50"],
    "current-dark": baseTheme.colors.blue["600"],
    // "upcoming-light": baseTheme.colors.blue["50"],
    "upcoming-light": baseTheme.colors.gray["50"],
    // "upcoming-light": "#e5ebff",
    "upcoming-dark": baseTheme.colors.blue["900"],
    "errorBubble-light": baseTheme.colors.red["100"],
    "errorBubble-dark": baseTheme.colors.red["600"],
  },
  progressCircle: {
    light: "#b4beff",
    dark: "#0a1bab",
  },
  progressBar: {
    light: "",
    dark: "",
  },
};
