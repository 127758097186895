import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

export const PanelStyled = styled(Box)`
  width: 100%;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    border-radius: 0;
  }
`;

PanelStyled.defaultProps = {
  _dark: {
    bg: "panel.dark",
  },
  _light: {
    bg: "panel.light",
  },
  p: { base: 4, md: 8 },
};
