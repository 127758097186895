// ====================================================================================
// Redux Reducers for App state
// ====================================================================================
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { HelpModal } from "@types";

export interface AppState {
  showHelpModal: boolean;
  helpModalHeading: string;
  helpModalContent: string;
}

const initialState: AppState = {
  showHelpModal: false,
  helpModalHeading: "",
  helpModalContent: "",
};

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Sets the state of the recorder
    showHelpModal: (state, action: PayloadAction<HelpModal>) => {
      state.showHelpModal = true;
      state.helpModalHeading = action.payload.heading;
      state.helpModalContent = action.payload.content;
    },
    hideHelpModal: (state) => {
      state.showHelpModal = false;
    },
  },
});

export const { showHelpModal, hideHelpModal } = appSlice.actions;
export default appSlice.reducer;
