// ====================================================================================
// Application configuration
// ====================================================================================
import packageJson from "../../package.json";

/**
 * Main App Config
 */
export const Config = {
  isProduction: process.env.REACT_APP_ENVIRONMENT === "production",
  version: packageJson.version,
  calibrationMoveResults: 6,
  skipAudioSetup: !(process.env.REACT_APP_ENVIRONMENT === "production"),
  utterancesToAllowBuild: 99,
  utterancesPerStage: 20,
  aesRetryAttempts: 30,
  aesRetryRate: 1000,
};
