// ====================================================================================
// Dashboard Page - when the user logs in. Holds the stages and gets user data
// ====================================================================================
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, getUserData } from "@store";
import { useCollectName } from "@hooks";
import { useUtterances, useVoiceBuilder } from "./hooks";
import { Panel } from "@components";
import { Stages } from "./components";
import { Divider, Skeleton, Text } from "@chakra-ui/react";

/**
 * Dashboard
 */
export const Dashboard: React.FC = () => {
  const voiceBuilder = useVoiceBuilder();
  const utterances = useUtterances();
  const dispatch = useDispatch<AppDispatch>();
  useCollectName();

  useEffect(() => {
    dispatch(getUserData());
    // eslint-disable-next-line
  }, []);

  return (
    <Panel title="Welcome to Voice Cloner">
      <Text>
        This process is designed to help clone your voice by reading
        personalized scripts designed especially for you.
      </Text>
      <Divider my={8} />
      <Skeleton isLoaded={!utterances.isLoading}>
        <Stages
          recordOnClick={utterances.handleStart}
          buildOnClick={voiceBuilder.start}
          builderIsError={voiceBuilder.isError}
          builderMsg={voiceBuilder.msg}
          builderLoading={voiceBuilder.isLoading}
        />
      </Skeleton>
    </Panel>
  );
};
