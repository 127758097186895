// ====================================================================================
// Draws the levelmeter using the analyser node and animation loop
// ====================================================================================
import React, { useContext, useEffect } from "react";
import { RecorderContext } from "@context";
import { useRecorderState, useAnimationFrame } from "@hooks";
import { Text, useColorModeValue, useToken } from "@chakra-ui/react";
import { dbFromFloat } from "@utils";
import { drawVolume } from "../utils";

/**
 * Draws the level meter during recording
 */
export const LevelMeter: React.FC = () => {
  const { recorderRef } = useContext(RecorderContext);
  const { recorderState } = useRecorderState();
  const isActive = recorderState === "RECORDING";
  const bgColor = useToken(
    "colors",
    useColorModeValue("meterBg.light", "meterBg.dark")
  );
  let lastReading = 0;

  /**
   * Calculates the peak value and draws the meter on each animation frame
   */
  const animate = (): void => {
    if (!isActive || !recorderRef.current?.analyser) return;

    // Create a new array buffer and fill it with the data from the analyser
    const bufferLength: number = recorderRef.current.analyser.fftSize;
    const buffer = new Float32Array(bufferLength);
    recorderRef.current.analyser.getFloatTimeDomainData(buffer);

    // Calculate peak values
    let maxSample = 0;
    for (let i = 0; i < buffer.length; i += 1) {
      const sample = Math.abs(buffer[i]);
      if (sample > maxSample) {
        maxSample = sample;
      }
    }

    // Smooth out the value changing and draw meter
    const meterValue = maxSample * 0.2 + lastReading * 0.8;
    lastReading = meterValue;
    draw(meterValue);
  };

  /**
   * Gets the context and draws the meter
   * @param value
   */
  const draw = (value: number): void => {
    // Get the canvas element and draw the meter
    const canvas = document.getElementById("meter-canvas") as HTMLCanvasElement;
    const volumeCtx = canvas?.getContext("2d");
    if (volumeCtx) drawVolume(volumeCtx, dbFromFloat(value), bgColor);
  };

  // Call the animate function
  useAnimationFrame(animate, isActive, 120);

  // Draw the meter on component load, otherwise the canvas will be empty
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => draw(0), []);

  return (
    <div>
      <canvas
        id="meter-canvas"
        width="250"
        height="20"
        style={{ marginTop: "12px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "2px",
        }}
      >
        <Text fontSize="xs" color="gray">
          -60db
        </Text>
        <Text fontSize="xs" color="gray">
          -40db
        </Text>
        <Text fontSize="xs" color="gray">
          -20db
        </Text>
        <Text fontSize="xs" color="gray">
          0db
        </Text>
      </div>
    </div>
  );
};
