// ====================================================================================
// Global audio player controlled by redux for utterance playback
// ====================================================================================
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, setIsPlaying } from "@store";
import { Howl } from "howler";

/**
 * Plays back an audio file using howler, controlled by Redux store.
 */
export function useAudioPlayback(): void {
  const dispatch = useDispatch<AppDispatch>();
  const audio = useRef<Howl | undefined>();

  // const canvas = document.getElementById("waveform");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const ctx = canvas?.getContext("2d");
  // const waveColor = useToken(
  //   "colors",
  //   useColorModeValue("waveform.light", "waveform.dark")
  // );
  // const cursorColor = useToken("colors", useColorModeValue("black", "white"));
  // const playbackWaveData = useSelector(
  //     (state: RootState) => state.recorder.playbackWaveData
  // );

  const playbackUrl = useSelector(
    (state: RootState) => state.recorder.playbackUrl
  );
  const playbackIsPlaying = useSelector(
    (state: RootState) => state.recorder.playbackIsPlaying
  );

  const onEnd = () => {
    dispatch(setIsPlaying(false));
    if (audio.current) {
      audio.current.stop();
      audio.current.unload();
    }
    audio.current = undefined;
  };

  /**
   * Controls playback based on the state
   */
  useEffect(() => {
    // check if the source has changed
    // if (audio.current?._src.length) {
    //   if (playbackUrl !== audio.current?._src[0]) {
    //     console.log(audio.current?._src[0]);
    //     console.log("source changed");
    //   }
    // }

    if (!playbackIsPlaying) {
      if (audio.current) {
        audio.current.stop();
        audio.current.unload();
      }
      audio.current = undefined;
    }

    if (playbackIsPlaying) {
      audio.current = new Howl({
        src: playbackUrl,
        format: "WAV",
        html5: true,
        onend: () => onEnd(),
      });

      audio.current.play();
    }

    // eslint-disable-next-line
  }, [playbackIsPlaying]);

  // useEffect(() => {
  //   if (!canvas) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // canvas.addEventListener("mousedown", function (e) {
  //   // let pos = 0;
  //   // if (recorderState) pos = getCursorPosition(canvas, e, ctx);
  //
  //   const width = ctx.canvas.width;
  //   const pos = 0.5;
  //
  //   console.log(playbackWaveData);
  //   drawWaveform(ctx, playbackWaveData, waveColor, pos / width, cursorColor);
  //   setTimeout(() => {
  //     // if (!audio.isPlaying) audio.play();
  //   }, 100);
  // });
  //   // eslint-disable-next-line
  // }, [canvas]);

  /**
   * Tracks the position of the current files playback and updates the cursor
   */
  // useInterval(
  //   () => {
  //     drawWaveform(
  //       ctx,
  //       playbackWaveData,
  //       waveColor,
  //       audio.current?.seek() / audio.current?.duration(),
  //       cursorColor
  //     );
  //   },
  //   50,
  //   playbackIsPlaying
  // );
}

// function getCursorPosition(canvasA: any, event: any): number {
//   const rect = canvasA.getBoundingClientRect();
//   return event.clientX - rect.left;
// }
