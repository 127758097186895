// ====================================================================================
// Record Page - Sets up functionality and renders the card layout
// ====================================================================================
import { useRedirect } from "@hooks";
import { Main } from "./components";
import {
  useHandsFree,
  useKeyboardControls,
  useRecorderHandler,
  useKeyboardHint,
} from "./hooks";

/**
 * Main Recording Page
 */
export const Record = () => {
  useRedirect();
  useRecorderHandler();
  useHandsFree();
  useKeyboardControls();
  useKeyboardHint();

  return (
    <>
      {/*<ProgressBar />*/}
      <Main />
    </>
  );
};
