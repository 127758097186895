// ====================================================================================
// AWS Config for endpoints, auth etc
// ====================================================================================
import Auth from "@aws-amplify/auth";
import { prod, staging } from "./UserPools";
import { Config } from "./Config";

const awsEnvironment = Config.isProduction ? prod : staging;
const awsCredentials = awsEnvironment.cognito;

/**
 * Auth header for jwt
 */
export const authHeader = async () => ({
  Authorization: `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`,
});

/**
 * Aws Config
 */
export const amplifyConfigData = {
  Auth: {
    region: awsCredentials.REGION,
    userPoolId: awsCredentials.USER_POOL_ID,
    identityPoolId: awsCredentials.IDENTITY_POOL_ID,
    userPoolWebClientId: awsCredentials.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "userData",
        endpoint: awsEnvironment.userData,
        custom_header: authHeader,
      },
      {
        name: "voiceBuild",
        endpoint: awsEnvironment.voiceBuild,
        custom_header: authHeader,
      },
      {
        name: "voiceStatus",
        endpoint: awsEnvironment.voiceStatus,
        custom_header: authHeader,
      },
      {
        name: "getUserData",
        endpoint: awsEnvironment.getUserData,
        custom_header: authHeader,
      },
      {
        name: "getText",
        endpoint: awsEnvironment.getText,
        custom_header: authHeader,
      },
      {
        name: "aesFeedback",
        endpoint: awsEnvironment.aesFeedback,
        custom_header: authHeader,
      },
      {
        name: "preview",
        endpoint: awsEnvironment.preview,
        custom_header: authHeader,
      },
      {
        name: "getFile",
        endpoint: awsEnvironment.getFile,
        custom_header: authHeader,
      },
    ],
  },
};
