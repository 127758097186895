export const readableErrors = (msg: string) => {
  switch (msg) {
    case "PreSignUp failed with error Malformed invitation code..":
      return "Invalid invitation code";
    case "PreSignUp failed with error The invitation has expired..":
      return "The invitation code has expired.";
    case "PreSignUp failed with error Malformed invitation..":
      return "Malformed invitation.";
    case "PreSignUp failed with error The invitation is not issued for this email address..":
      return "The invitation is not issued for this email address.";
    case "PreSignUp failed with error Organization names can not contain special characters..":
      return "Organization names can not contain special characters.";
    default:
      return msg;
  }
};
