import React from "react";
import { RecorderState } from "@types";
import {
  Text,
  Grid,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { RecordButton } from "@components";
import { SelectDevice } from "./SelectDevice";
import { ResultCard } from "./ResultCard";
import { ICalibrationResults } from "../utils/CalculateResults";
import { Config } from "@config";
import { AutoGain } from "./AutoGain";

type Props = {
  result: ICalibrationResults | undefined;
  recorderState: RecorderState;
  nextOnClick: () => void;
  recordOnClick: () => void;
  count: number;
};

/**
 * Microphone setup
 * @param result
 * @param recorderState
 * @param nextOnClick
 * @param recordOnClick
 * @param count
 */
export const MicCheck: React.FC<Props> = ({
  result,
  recorderState,
  nextOnClick,
  recordOnClick,
  count,
}) => {
  return (
    <>
      <Text mb={8}>
        Please follow the 2 step process to ensure good audio quality for your
        current session.
      </Text>
      {/* <Divider my={8} /> */}
      <Grid templateColumns={["1fr", "1fr", "1fr 1fr"]} gap={8}>
        <Box>
          <Box borderWidth="1px" p={4} borderRadius="6px">
            <Text mb={8} fontWeight="semibold">
              Choose Input Device
            </Text>
            <SelectDevice />
            {!Config.isProduction && <AutoGain />}
          </Box>
          <Box borderWidth="1px" p={4} mt={8} borderRadius="6px">
            <Text mb={8} fontWeight="semibold">
              Check Microphone Level
            </Text>
            <Alert status="info" mt={4} mb={8} bg="blue.50">
              <AlertIcon />
              <AlertDescription>
                Press the microphone button below and count to 5
              </AlertDescription>
            </Alert>

            <Grid placeItems="center" mb={4}>
              <RecordButton
                recorderState={recorderState}
                onClick={recordOnClick}
                isCountdown={recorderState === "RECORDING"}
                count={count}
              />
            </Grid>
          </Box>
        </Box>

        {/* Result Cards */}
        <ResultCard
          type={result?.result}
          retryOnClick={recordOnClick}
          nextOnClick={nextOnClick}
        >
          {result?.result === "LOUD" && (
            <Text>
              Your microphone is too loud, please reduce your input gain.
            </Text>
          )}
          {result?.result === "QUIET" && (
            <Text>
              Your microphone seems too quiet. Increase the input gain and check
              that your audio input is correct.
            </Text>
          )}
          {result?.result === "PASS" && <Text>Looks Good!</Text>}
          {result === undefined && (
            <Text color="gray.400">Waiting for Results</Text>
          )}
        </ResultCard>
      </Grid>
    </>
  );
};
