// ====================================================================================
// Handles the AES responses for an individual utterance
// ====================================================================================
import { useDispatch } from "react-redux";
import { AppDispatch, setDeviceQuality, updateUtterance } from "@store";
import { VoiceClonerAPI } from "@api";
import { Utterance } from "@types";
import { calculateAESErrors } from "@utils";
import { Config } from "@config";

/**
 * Hook for using Aflorithmics AES for per utterance feedback
 */
export const useAES = () => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Gets the utterance feedback, keeps calling the endpoint until the data has arrived, then updates the utterance
   * @param uData
   * @param retry used to stop the recursion at a specified number
   */
  const getAESResults = (uData: Utterance, retry = 0) => {
    // Wait two seconds before executing the request, the AES takes time
    setTimeout(async () => {
      // Cancel if we exceed the number of retries
      if (retry >= Config.aesRetryAttempts) {
        dispatch(
          updateUtterance({
            id: uData.id,
            errors: [
              {
                type: "CONNECTION",
                name: "Connection Issue",
                description:
                  "Could not retrieve results, please check your internet connection and try again",
              },
            ],
          })
        );
        return;
      }

      const data = await VoiceClonerAPI.getAESUtteranceFeedback({
        verticalId: uData.vertical,
        utteranceId: uData.id,
      });

      // If the data hasn't arrived, call the function again
      if (!data || data.valid_audio === undefined) {
        getAESResults(uData, (retry += 1));
        return;
      }

      if (data.assess_spectral_bandwidth_passed !== undefined) {
        dispatch(setDeviceQuality(data.assess_spectral_bandwidth_passed));
      }

      const errors = calculateAESErrors(data);
      dispatch(
        updateUtterance({
          id: uData.id,
          errors,
        })
      );
    }, Config.aesRetryRate);
  };

  return {
    getAESResults,
  };
};
