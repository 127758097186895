// ====================================================================================
// Sign in page
// ====================================================================================
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { signIn } from "@store";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { AuthHeader } from "./AuthHeader";
import { useEnterKey } from "@hooks";

/**
 * Sign in component
 */
export const SignIn: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.auth.signInLoading);
  const error = useSelector((state: RootState) => state.auth.signInError);
  const [email, setEmail] = useState<string>("");
  useEnterKey(() => onSubmit());

  /**
   * Handles the submit function
   */
  const onSubmit = () => {
    if (email !== "") dispatch(signIn(email));
  };

  return (
    <>
      <AuthHeader title="Sign In" />
      <Text mb={12}>Sign in to your account to start cloning your voice.</Text>

      <FormControl mb={12} isInvalid={error !== ""}>
        <FormLabel htmlFor="email">Email address</FormLabel>
        <Input
          placeholder="example@email.com"
          id="email"
          size="lg"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Flex justify="center">
        <Button
          onClick={() => onSubmit()}
          isLoading={isLoading}
          isDisabled={email.length === 0}
        >
          Sign In
        </Button>
      </Flex>

      <Text textAlign="center" fontSize="sm" mt={12}>
        For the best results, please use Google Chrome
      </Text>
    </>
  );
};
