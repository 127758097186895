// ====================================================================================
// Company / Brand Icons
// ====================================================================================
import { createIcon } from "@chakra-ui/react";

export const ParrotLogo = createIcon({
  displayName: "ParrotLogo",
  viewBox: "0 0 160 160",
  path: (
    <>
      <path
        d="M148.595 42.0485C148.555 42.0282 148.511 42.0176 148.465 42.0176C148.42 42.0176 148.376 42.0282 148.335 42.0485L133.49 53.0323L125.201 57.4836L121.504 60.8654C121.486 60.8962 121.38 60.8299 121.38 60.8654C121.38 60.9009 121.233 60.9358 121.251 60.9666C121.238 60.9937 121.336 61.1545 121.485 61.1545C121.485 61.1844 121.491 61.2141 121.504 61.2412L135.57 68.6697L146.805 69.5368C146.827 69.5472 146.852 69.5526 146.877 69.5526C146.902 69.5526 146.926 69.5472 146.949 69.5368C146.949 69.5368 155.617 56.2046 155.617 56.06L148.672 42.1641C148.692 42.129 148.482 42.2047 148.482 42.1641C148.482 42.1235 148.471 42.0837 148.451 42.0485"
        fill="#F18605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.794 25.499L118.07 38.0005L122.237 49.1129L124.057 51.2972L132.346 49.8809L141.184 42.6547L127.794 25.499Z"
        fill="#C01C75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.591 24.4474L139.868 19.2735L114.077 13.377L122.237 28.4941H128.835L141.168 42.6573L150.018 42.17L152.865 42.8308L154.858 36.9631L148.591 24.4474Z"
        fill="#EE4263"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.009 13.377L97.2323 19.9451L80.7373 27.7715L91.6761 38.0028L98.6152 35.1421L107.02 36.5006L118.573 38.206L128.768 28.4941L114.009 13.377Z"
        fill="#F18605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5738 13L65.1577 19.6481L51.5254 26.036L65.2857 28.2796L80.7539 27.7703L114.026 13.3758L90.5738 13Z"
        fill="#D4F40E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.7539 27.7704L51.5254 26.0361L69.4529 49.1156L74.4288 50.8074L80.7539 47.7265L92.3067 36.3262L80.7539 27.7704Z"
        fill="#009B7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5175 26.0361L14.4331 49.6223L28.4408 64.3926L52.784 58.8389L74.4209 50.8074L51.5175 26.0361Z"
        fill="#45BF55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4698 49.6221L0 76.4166L13.4879 99.7716L20.8358 85.2301L28.4776 64.3923L14.4698 49.6221Z"
        fill="#97ED8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0398 75.6925L63.8966 64.395L74.4341 50.8057L28.4541 64.3908L40.2828 71.3403L51.0398 75.6925Z"
        fill="#F1B605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5872 81.0977L87.0347 74.1028L80.9695 63.4659L74.4422 50.8057L63.8966 62.0241L51.0479 75.6925L69.4528 86.6199L98.3276 94.0759L90.5872 81.0977Z"
        fill="#C01C75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0969 75.6933L28.5112 64.3916L13.5215 99.7709L33.5768 103.818H52.05L51.3949 76.8958L51.0969 75.6933Z"
        fill="#F57948"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.3276 94.0747L69.4745 84.2761L51.0479 75.6914L52.001 103.816L66.6747 100.507L93.0667 110.231L98.3276 94.0747Z"
        fill="#DF4793"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5017 99.7705L3.79736 126.16L40.2463 130.843L52.0302 103.817L13.5017 99.7705Z"
        fill="#04D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9825 98.9033L52.0216 103.817L40.2378 130.843L79.7772 147.145L75.0075 117.178L66.9825 98.9033Z"
        fill="#001EB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.492 106.594L102.423 99.7145L98.3506 94.0781L90.0903 108.733L91.6747 110.234L126.539 129.746L112.511 107.456L111.492 106.594Z"
        fill="#5C186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.535 129.745L90.639 109.05L90.086 108.731L66.9805 98.9033L79.7752 147.145L101.87 138.214L127.026 147.145L126.535 129.745Z"
        fill="#05147D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.68 101.898L117.897 105.357L118.451 106.924L126.518 129.747L111.471 106.594L116.68 101.898Z"
        fill="#80196D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.596 38.2063L107.043 36.501L113.902 47.7273L115.217 47.3113L123.997 51.3001L118.596 38.2063Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5397 59.041L80.9761 63.4634L87.0413 74.1003L90.5938 81.0952L98.3341 94.0734L100.125 86.7605L111.1 71.759L93.5397 59.041Z"
        fill="#9C9B9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.233 47.3057H113.467L106.193 55.1099L93.5425 59.0409L111.103 71.759L115.29 65.7812L124.013 51.2945L115.233 47.3057Z"
        fill="#C6C6C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.053 36.5001L98.6484 35.1416L92.3232 36.3267L79.7307 46.2121L74.4453 50.8079L80.9726 63.5548L93.5363 59.1324L106.187 55.2014L115.14 47.3104L107.053 36.5001ZM100.013 47.7272L100.092 49.883L97.2345 49.1162L94.4605 49.0447L93.0673 46.3381V43.56L94.085 40.3733L97.2345 40.7819L99.5148 40.171L100.013 42.171L102.721 43.206L102.791 46.3381L100.013 47.7272Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.734 43.2313L101.78 41.8149L99.5276 40.1963L97.0149 39.9072H96.986L99.1233 42.3352L100.134 44.1273L99.932 46.035L97.7658 47.1045L96.5239 46.2951L95.4264 44.7343L97.2339 42.1672L96.986 39.9072L94.04 40.3986L92.5093 42.7399L92.3071 46.1506L94.4155 49.0122L97.3615 50.284L100.105 49.8504L102.329 48.0294L103.253 45.7749L102.734 43.2313Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.514 53.1487L124.012 51.2988L115.289 64.7106L111.102 71.7633L113.901 71.3424L117.484 68.815L119.458 65.7862L121.355 61.0686L125.052 57.6867L133.514 53.1487Z"
        fill="#001EB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.925 41.2959L141.145 42.6544L132.307 49.8806L124.018 51.2969L133.52 53.1468L137.39 50.3719L143.34 46.0363L148.596 42.2498L151.108 46.0363L152.842 42.8278L149.925 41.2959Z"
        fill="#0B42FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.334 83.8419L111.084 71.7637L100.109 86.7652L98.3179 94.078L102.39 99.7144L111.459 106.594L124.225 103.877L127.792 90.7872L115.334 83.8419Z"
        fill="#1A2040"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.874 83.8472L135.419 68.4988L121.354 61.0703L117.483 68.8168L120.848 78.287L126.404 85.2322L131.96 89.3994L133.349 93.5665L139.578 97.0691L140.098 98.2416L137.874 83.8472Z"
        fill="#303473"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.498 62.4832L151.509 45.3428L148.621 42.25L147.09 69.3625L147.242 71.3387L160 80.1729L158.498 62.4832Z"
        fill="#F1B605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.983 80.1784L147.073 69.3681L135.434 68.501L137.889 83.8493L140.112 98.2438L151.408 90.7909L159.983 80.1784Z"
        fill="#FFDB00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.968 80.1748L155.434 99.2229L146.856 113.242L143.071 106.068L140.098 98.2402L159.968 80.1748Z"
        fill="#FFD00F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.849 113.247L140.091 98.2451L139.831 108.188L136.279 118.016L132.697 123.045L141.189 118.941L146.849 113.247Z"
        fill="#FFDB00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.578 97.0871L137.874 93.2717L135.679 88.2712L129.902 83.1262L123.028 76.6226L117.483 68.8184L111.1 71.7666L109.021 86.9416L126.465 90.8726L124.241 103.88L139.578 97.0871Z"
        fill="#252C59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.6221 43.5571L96.5091 46.2969L95.4111 44.7367L96.7403 42.3096L98.6221 43.5571Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1585 42.3343L96.9931 39.9072L96.791 42.3054L97.4551 44.4436L96.5601 46.2928L97.8015 47.1018L99.9669 46.0327L100.169 44.1257L99.1585 42.3343Z"
        fill="#1A2040"
      />
      <path
        d="M148.595 42.0485C148.555 42.0282 148.511 42.0176 148.465 42.0176C148.42 42.0176 148.376 42.0282 148.335 42.0485L133.49 53.0323L125.201 57.4836L121.504 60.8654C121.486 60.8962 121.38 60.8299 121.38 60.8654C121.38 60.9009 121.233 60.9358 121.251 60.9666C121.238 60.9937 121.336 61.1545 121.485 61.1545C121.485 61.1844 121.491 61.2141 121.504 61.2412L135.57 68.6697L146.805 69.5368C146.827 69.5472 146.852 69.5526 146.877 69.5526C146.902 69.5526 146.926 69.5472 146.949 69.5368C146.949 69.5368 155.617 56.2046 155.617 56.06L148.672 42.1641C148.692 42.129 148.482 42.2047 148.482 42.1641C148.482 42.1235 148.471 42.0837 148.451 42.0485"
        fill="#F18605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.794 25.499L118.07 38.0005L122.237 49.1129L124.057 51.2972L132.346 49.8809L141.184 42.6547L127.794 25.499Z"
        fill="#C01C75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.591 24.4474L139.868 19.2735L114.077 13.377L122.237 28.4941H128.835L141.168 42.6573L150.018 42.17L152.865 42.8308L154.858 36.9631L148.591 24.4474Z"
        fill="#EE4263"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.009 13.377L97.2323 19.9451L80.7373 27.7715L91.6761 38.0028L98.6152 35.1421L107.02 36.5006L118.573 38.206L128.768 28.4941L114.009 13.377Z"
        fill="#F18605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5738 13L65.1577 19.6481L51.5254 26.036L65.2857 28.2796L80.7539 27.7703L114.026 13.3758L90.5738 13Z"
        fill="#D4F40E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.7539 27.7704L51.5254 26.0361L69.4529 49.1156L74.4288 50.8074L80.7539 47.7265L92.3067 36.3262L80.7539 27.7704Z"
        fill="#009B7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5175 26.0361L14.4331 49.6223L28.4408 64.3926L52.784 58.8389L74.4209 50.8074L51.5175 26.0361Z"
        fill="#45BF55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4698 49.6221L0 76.4166L13.4879 99.7716L20.8358 85.2301L28.4776 64.3923L14.4698 49.6221Z"
        fill="#97ED8A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0398 75.6925L63.8966 64.395L74.4341 50.8057L28.4541 64.3908L40.2828 71.3403L51.0398 75.6925Z"
        fill="#F1B605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5872 81.0977L87.0347 74.1028L80.9695 63.4659L74.4422 50.8057L63.8966 62.0241L51.0479 75.6925L69.4528 86.6199L98.3276 94.0759L90.5872 81.0977Z"
        fill="#C01C75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0969 75.6933L28.5112 64.3916L13.5215 99.7709L33.5768 103.818H52.05L51.3949 76.8958L51.0969 75.6933Z"
        fill="#F57948"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5017 99.7705L3.79736 126.16L40.2463 130.843L52.0302 103.817L13.5017 99.7705Z"
        fill="#04D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9825 98.9033L52.0216 103.817L40.2378 130.843L79.7772 147.145L75.0075 117.178L66.9825 98.9033Z"
        fill="#001EB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.492 106.594L102.423 99.7145L98.3506 94.0781L90.0903 108.733L91.6747 110.234L126.539 129.746L112.511 107.456L111.492 106.594Z"
        fill="#5C186A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.535 129.745L90.639 109.05L90.086 108.731L66.9805 98.9033L79.7752 147.145L101.87 138.214L127.026 147.145L126.535 129.745Z"
        fill="#05147D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.68 101.898L117.897 105.357L118.451 106.924L126.518 129.747L111.471 106.594L116.68 101.898Z"
        fill="#80196D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.596 38.2063L107.043 36.501L113.902 47.7273L115.217 47.3113L123.997 51.3001L118.596 38.2063Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5397 59.041L80.9761 63.4634L87.0413 74.1003L90.5938 81.0952L98.3341 94.0734L100.125 86.7605L111.1 71.759L93.5397 59.041Z"
        fill="#9C9B9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.233 47.3057H113.467L106.193 55.1099L93.5425 59.0409L111.103 71.759L115.29 65.7812L124.013 51.2945L115.233 47.3057Z"
        fill="#C6C6C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.053 36.5001L98.6484 35.1416L92.3232 36.3267L79.7307 46.2121L74.4453 50.8079L80.9726 63.5548L93.5363 59.1324L106.187 55.2014L115.14 47.3104L107.053 36.5001ZM100.013 47.7272L100.092 49.883L97.2345 49.1162L94.4605 49.0447L93.0673 46.3381V43.56L94.085 40.3733L97.2345 40.7819L99.5148 40.171L100.013 42.171L102.721 43.206L102.791 46.3381L100.013 47.7272Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.734 43.2313L101.78 41.8149L99.5276 40.1963L97.0149 39.9072H96.986L99.1233 42.3352L100.134 44.1273L99.932 46.035L97.7658 47.1045L96.5239 46.2951L95.4264 44.7343L97.2339 42.1672L96.986 39.9072L94.04 40.3986L92.5093 42.7399L92.3071 46.1506L94.4155 49.0122L97.3615 50.284L100.105 49.8504L102.329 48.0294L103.253 45.7749L102.734 43.2313Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.514 53.1487L124.012 51.2988L115.289 64.7106L111.102 71.7633L113.901 71.3424L117.484 68.815L119.458 65.7862L121.355 61.0686L125.052 57.6867L133.514 53.1487Z"
        fill="#001EB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.925 41.2959L141.145 42.6544L132.307 49.8806L124.018 51.2969L133.52 53.1468L137.39 50.3719L143.34 46.0363L148.596 42.2498L151.108 46.0363L152.842 42.8278L149.925 41.2959Z"
        fill="#0B42FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.334 83.8419L111.084 71.7637L100.109 86.7652L98.3179 94.078L102.39 99.7144L111.459 106.594L124.225 103.877L127.792 90.7872L115.334 83.8419Z"
        fill="#1A2040"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.874 83.8472L135.419 68.4988L121.354 61.0703L117.483 68.8168L120.848 78.287L126.404 85.2322L131.96 89.3994L133.349 93.5665L139.578 97.0691L140.098 98.2416L137.874 83.8472Z"
        fill="#303473"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.498 62.4832L151.509 45.3428L148.621 42.25L147.09 69.3625L147.242 71.3387L160 80.1729L158.498 62.4832Z"
        fill="#F1B605"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.983 80.1784L147.073 69.3681L135.434 68.501L137.889 83.8493L140.112 98.2438L151.408 90.7909L159.983 80.1784Z"
        fill="#FFDB00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.968 80.1748L155.434 99.2229L146.856 113.242L143.071 106.068L140.098 98.2402L159.968 80.1748Z"
        fill="#FFD00F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.849 113.247L140.091 98.2451L139.831 108.188L136.279 118.016L132.697 123.045L141.189 118.941L146.849 113.247Z"
        fill="#FFDB00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.578 97.0871L137.874 93.2717L135.679 88.2712L129.902 83.1262L123.028 76.6226L117.483 68.8184L111.1 71.7666L109.021 86.9416L126.465 90.8726L124.241 103.88L139.578 97.0871Z"
        fill="#252C59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.6221 43.5571L96.5091 46.2969L95.4111 44.7367L96.7403 42.3096L98.6221 43.5571Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1585 42.3343L96.9931 39.9072L96.791 42.3054L97.4551 44.4436L96.5601 46.2928L97.8015 47.1018L99.9669 46.0327L100.169 44.1257L99.1585 42.3343Z"
        fill="#1A2040"
      />
    </>
  ),
});
