import { roundRect } from "@utils";

/**
 * Draws the waveform to the canvas
 * @param ctx CanvasRenderingContext2D
 * @param peak array with db values
 * @param waveColor
 * @param cursor
 * @param cursorColor
 */
export function drawWaveform(
  ctx: CanvasRenderingContext2D,
  peak: number[],
  waveColor: string,
  cursor?: number,
  cursorColor = "white"
) {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const mid = height / 2;

  // We need to translate everything half a pixel to render correctly
  // ctx.translate(0.5, -0.5);
  ctx.clearRect(0, 0, width, height);

  // X axis line
  ctx.beginPath();
  ctx.moveTo(width, mid);
  ctx.lineTo(0, mid);
  ctx.strokeStyle = waveColor;
  ctx.lineWidth = 1;
  ctx.stroke();

  // // Y axis line
  // ctx.beginPath();
  // ctx.moveTo(width - 1, 0);
  // ctx.lineTo(width - 1, height);
  // ctx.strokeStyle = "#D3D9E1";
  // ctx.lineWidth = 1;
  // ctx.stroke();

  // // Y axis line
  // ctx.beginPath();
  // ctx.moveTo(0, 0);
  // ctx.lineTo(0, height);
  // ctx.strokeStyle = "#D3D9E1";
  // ctx.lineWidth = 1;
  // ctx.stroke();

  // // Threshold line
  // ctx.beginPath();
  // ctx.moveTo(0, mid - (threshold / 100) * mid);
  // ctx.lineTo(width - 1, mid - (threshold / 100) * mid);
  // ctx.strokeStyle = "#ccc";
  // ctx.lineWidth = 1;
  // ctx.stroke();

  // // Threshold line
  // ctx.beginPath();
  // ctx.moveTo(0, mid - (threshold2 / 100) * mid);
  // ctx.lineTo(width - 1, mid - (threshold2 / 100) * mid);
  // ctx.strokeStyle = "#ccc";
  // ctx.lineWidth = 1;
  // ctx.stroke();

  const barWidth = width / peak.length;

  // Bars
  ctx.beginPath();

  for (let x = 0; x < peak.length; x += 1) {
    const meterCalc = ((Math.max(peak[x], -60.0) + 60) / 60) * 100;

    if (meterCalc > 5) {
      roundRect(
        ctx,
        width - x * barWidth, // Draw backwards from max width
        // x * 3,
        mid - (meterCalc / 100) * mid, // Starting to draw from on Y
        barWidth / 2, // Bar width
        (meterCalc / 100) * mid * 2,
        1
      );

      // Fill with gradient
      if (peak[x] > -0.1) {
        ctx.fillStyle = "#EA5858";
      } else {
        ctx.fillStyle = waveColor;
      }

      ctx.fill();
    }
  }

  if (cursor) {
    ctx.beginPath();
    ctx.moveTo(cursor * width, 0);
    ctx.lineTo(cursor * width, height);
    ctx.strokeStyle = cursorColor;
    ctx.lineWidth = 1;
  }
  ctx.stroke();

  ctx.setTransform(1, 0, 0, 1, 0, 0);
}
