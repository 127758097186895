// ====================================================================================
// Audio Setup page for setting gain and checking background noise
// ====================================================================================
import { Panel } from "@components";
import { MicCheck, NoiseCheck } from "./components";
import { useCalibrationRecorder } from "./hooks";

/**
 * Calibration Page
 */
export const Calibration = () => {
  const {
    saveOnClick,
    recordOnClick,
    nextOnClick,
    recorderState,
    micResult,
    noiseResult,
    timer,
    stage,
  } = useCalibrationRecorder();

  return (
    <Panel title="Audio Setup" hasBottomBar={true}>
      {stage === "MIC_CHECK" && (
        <MicCheck
          result={micResult}
          recorderState={recorderState}
          recordOnClick={recordOnClick}
          nextOnClick={nextOnClick}
          count={timer}
        />
      )}
      {stage === "NOISE_CHECK" && (
        <NoiseCheck
          result={noiseResult}
          recorderState={recorderState}
          recordOnClick={recordOnClick}
          nextOnClick={saveOnClick}
          count={timer}
        />
      )}
    </Panel>
  );
};
