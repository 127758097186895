import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "./useAuthState";

/**
 * Custom Hook for redirecting to collect name
 */
export const useCollectName = () => {
  const navigate = useNavigate();
  const { collectName } = useAuthState();

  useEffect(() => {
    if (collectName) {
      navigate("/signup");
    }
    // eslint-disable-next-line
  }, []);
};
