import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useVerticalsState } from "./useVerticalsState";
import { useRecorderState } from "./useRecorderState";
import { useAuthState } from "./useAuthState";

/**
 * Custom Hook for redirecting
 */
export const useRedirect = () => {
  const navigate = useNavigate();
  const { hasRunCalibration } = useRecorderState();
  const { utterancesRemaining } = useVerticalsState();
  const { collectName } = useAuthState();

  useEffect(() => {
    if (utterancesRemaining === 0) {
      // TODO FIX
      //navigate("/");
    } else if (!hasRunCalibration) {
      navigate("/calibration");
    } else if (collectName) {
      navigate("/signup");
    }
    // eslint-disable-next-line
  }, []);
};
