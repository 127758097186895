// ====================================================================================
// Auth pages
// ====================================================================================
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SignIn, ConfirmationCode, SignUp, CollectName } from "./components";
import { Panel } from "@components";
import { useAuthState } from "@hooks";

/**
 * Controller for the Auth components
 */
export const Auth: React.FC = () => {
  const { authStage, collectName } = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect the user if they are logged in
  useEffect(() => {
    if (authStage === "LOGGED_IN" && !collectName) {
      navigate("/");
    }
  }, [authStage, collectName, navigate]);

  return (
    <Panel isSmall>
      {/* Show signup is at /signup, otherwise signin */}
      {location.pathname === "/" && authStage === "SIGN_IN" && <SignIn />}
      {location.pathname === "/signup" && authStage === "SIGN_IN" && <SignUp />}
      {authStage === "CONFIRMATION" && <ConfirmationCode />}
      {authStage === "LOGGED_IN" && collectName && <CollectName />}
    </Panel>
  );
};
