// ====================================================================================
// Confirmation Code OTP page
// ====================================================================================
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AppDispatch, RootState } from "@store";
import { signIn, submitCode } from "@store";
import {
  Button,
  Input,
  FormLabel,
  Text,
  FormControl,
  FormErrorMessage,
  Flex,
  Link,
  useToast,
} from "@chakra-ui/react";
import { AuthHeader } from "./AuthHeader";
import { useEnterKey } from "@hooks";

/**
 * Confirmation Code Component
 */
export const ConfirmationCode: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.auth.codeLoading);
  const email = useSelector((state: RootState) => state.auth.email);
  const error = useSelector((state: RootState) => state.auth.codeError);
  const [code, setCode] = useState<string>("");
  const toast = useToast();

  useEnterKey(() => onSubmit());

  /**
   * Handles the submit button
   */
  const onSubmit = () => {
    if (code !== "") dispatch(submitCode(code));
  };

  /**
   * Handlws resending the code (same as signing in again)
   */
  function resendCode() {
    if (email) {
      dispatch(signIn(email));
      toast({
        title: "Confirmation Code",
        description: "Your code has been resent",
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <AuthHeader title={"You're almost there!"} />
      <Text mb={12}>
        Check your email! We have sent you a verification code.
      </Text>

      <FormControl mb={12} isInvalid={error !== ""}>
        <FormLabel htmlFor="confirmationCode">Confirmation Code</FormLabel>
        <Input
          value={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            parseInt(e.target.value) <= 999999 && setCode(e.target.value)
          }
          placeholder="Paste your code here"
          id="confirmationCode"
          size="lg"
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Flex justify="center">
        <Button
          onClick={() => onSubmit()}
          isLoading={isLoading}
          isDisabled={code.length !== 6}
        >
          Submit Code
        </Button>
      </Flex>

      <Text textAlign="center" fontSize="sm" mt={12}>
        Didn't receive your code? Click{" "}
        <Link
          color="blue"
          onClick={() => resendCode()}
          data-testid="resend-code"
        >
          here
        </Link>
        .
      </Text>
    </>
  );
};
