// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
import { colors } from "./Colors";
import { components } from "./Components";

// 2. Call `extendTheme` and pass your custom values
export const Theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  // Styling
  colors,
  components,
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },

  // Additional theme defaults
  fonts: {
    body: "Montserrat, system-ui, sans-serif",
    heading: "Montserrat, Roboto",
    mono: "Menlo, monospace",
  },
});
