import styled from "@emotion/styled";

// Script wrapper
export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  gap: 16px;
  padding: calc(50vh - 100px) 0;
  overflow-y: auto;
`;
