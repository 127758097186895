import { roundRect } from "@utils";

/**
 * Draws the volume meter to canvas
 * @param {*} ctx Canvas context
 * @param {*} peakDb Peak value in decibles
 * @param bgColor hex value for the meter background
 */
export function drawVolume(
  ctx: CanvasRenderingContext2D,
  peakDb: number,
  bgColor: string
) {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  // Calculate the scaling from Db -60 to 0 -> 0 to 100%
  let meterCalc = ((Math.max(peakDb, -60.0) + 60) / 60) * 100;

  // Set a minimum width for the bar
  if (meterCalc < 8) meterCalc = 8;

  ctx.clearRect(0, 0, width, height);
  ctx.beginPath();

  // Draw outside grey rectangle
  ctx.fillStyle = bgColor;
  roundRect(ctx, 0, 0, width, height, 12);

  // Create gradient
  const grd = ctx.createLinearGradient(0, 0, width - 4, 0);
  grd.addColorStop(0.45, "#67CF91");
  grd.addColorStop(0.79, "#ffda77");
  grd.addColorStop(0.97, "#EA5858");

  // Fill with gradient
  ctx.fillStyle = grd;

  // Draw bar
  roundRect(ctx, 2, 2, (meterCalc / 100) * width - 4, height - 4, 10);
}
