// ====================================================================================
// Redux reducer for the Recorder
// ====================================================================================

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  ISetSilenceDetectorThresholds,
  Utterance,
  UtteranceUpdate,
} from "@types";
import { Config } from "../config/Config";

export interface RecorderState {
  state: "IDLE" | "RECORDING" | "ARM";
  completed: Utterance[];
  upcoming: Utterance[];
  autoStart: boolean;
  autoStartCount: number;
  silenceDetectorActive: boolean;
  silenceDetectorFirstSoundThreshold: number;
  silenceDetectorSilenceThreshold: number;
  backgroundLevel: number;
  hasRunCalibration: boolean;
  countdownSpeed: number;
  countdownLength: number;
  audioDeviceId: string;
  audioDeviceName: string;
  playbackUrl: string;
  playbackWaveData: number[];
  playbackId: string;
  playbackIsPlaying: boolean;
  uploading: boolean;
  uploadError: string;
  isPaused: boolean;
  autoGain: boolean;
  deviceQuality: number;
  previousPage: string;
}

const initialState: RecorderState = {
  state: "IDLE",
  completed: [],
  upcoming: [],
  autoStart: false,
  autoStartCount: 3,
  silenceDetectorActive: true,
  silenceDetectorFirstSoundThreshold: -22.0,
  silenceDetectorSilenceThreshold: -36.0,
  backgroundLevel: -45.0,
  hasRunCalibration: Config.skipAudioSetup,
  countdownSpeed: 200,
  countdownLength: 3,
  audioDeviceId: "",
  audioDeviceName: "",
  playbackUrl: "",
  playbackWaveData: [],
  playbackId: "",
  playbackIsPlaying: false,
  uploading: false,
  uploadError: "",
  isPaused: false,
  autoGain: false,
  deviceQuality: 2, // Wont show until we have a result
  previousPage: "/",
};

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

/**
 * Recorder Reducer
 */
export const recorderSlice = createSlice({
  name: "recorder",
  initialState,
  reducers: {
    // Sets the state of the recorder
    setState: (state, action: PayloadAction<RecorderState["state"]>) => {
      state.state = action.payload;
    },
    // Load utterances
    prepareUtterances: (
      state,
      action: PayloadAction<{ utterances: Utterance[]; previousPage?: string }>
    ) => {
      state.completed = [];
      state.upcoming = action.payload.utterances;
      if (action.payload.previousPage)
        state.previousPage = action.payload.previousPage;
      state.state = "IDLE";
    },
    // Moves an utterance from upcoming to completed
    nextUtterance: (state, action: PayloadAction<Utterance>) => {
      state.completed.push(action.payload);
      state.upcoming.shift();
    },
    // Moves an item from completed to the top of the upcoming pile
    retakeUtterance: (state, action: PayloadAction<Utterance>) => {
      state.upcoming.unshift(action.payload);
      const index = state.completed.findIndex(
        (item: Utterance) => item.id === action.payload.id
      );
      if (index !== -1) state.completed.splice(index, 1);
    },
    // Moves an utterance from upcoming to completed
    removeCompleted: (state, action: PayloadAction<number>) => {
      state.completed.splice(action.payload, 1);
    },
    // Activates / Deactivates silence detector
    toggleSilenceDetector: (state) => {
      state.silenceDetectorActive = !state.silenceDetectorActive;
    },
    // Activates / Deactivates Auto Start
    toggleAutoStart: (state) => {
      state.autoStart = !state.autoStart;
    },
    // Auto start counter
    setAutoStartCount: (state, action: PayloadAction<number>) => {
      state.autoStartCount = action.payload;
    },
    // Sets the media streams device Id
    setAudioDeviceId: (state, action: PayloadAction<string>) => {
      state.audioDeviceId = action.payload;
    },
    // Sets the media streams device name
    setAudioDeviceName: (state, action: PayloadAction<string>) => {
      state.audioDeviceName = action.payload;
    },
    // Sets the media streams autogain param
    toggleAutoGain: (state) => {
      state.autoGain = !state.autoGain;
    },
    // Sets the thresholds after calibration
    setSilenceDetectorThresholds: (
      state,
      action: PayloadAction<ISetSilenceDetectorThresholds>
    ) => {
      state.silenceDetectorFirstSoundThreshold =
        action.payload.firstSoundThreshold;
      state.silenceDetectorSilenceThreshold = action.payload.silenceThreshold;
    },
    // Sets the hasRunCalibration flag
    setHasRunCalibration: (state, action: PayloadAction<boolean>) => {
      state.hasRunCalibration = action.payload;
    },
    // Sets countdown length
    setCountdownLength: (state, action: PayloadAction<number>) => {
      state.countdownLength = action.payload;
    },
    // Sets countdown length
    setPlayback: (state, action: PayloadAction<string>) => {
      const url = state.completed.find(
        (item: Utterance) => item.id === action.payload
      );
      state.playbackUrl = url?.audio || "";
      state.playbackWaveData = url?.waveform || [];
      state.playbackId = action.payload;
      state.playbackIsPlaying = true;
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.playbackIsPlaying = action.payload;
    },
    // Sets the state of the recorder
    toggleIsPaused: (state) => {
      state.isPaused = !state.isPaused;
    },
    // Sets the state of the recorder
    setDeviceQuality: (state, action: PayloadAction<number>) => {
      state.deviceQuality = action.payload;
    },
    // Moves an utterance from upcoming to completed
    updateUtterance: (state, action: PayloadAction<UtteranceUpdate>) => {
      // TODO should be find method
      state.completed = state.completed.map((item) => {
        if (item.id !== action.payload.id) return item;
        if (item.status !== "LOADING") return item;
        if (action.payload.errors.length > 0) {
          item.status = "FAIL";
          item.errors = action.payload.errors;
        } else {
          item.status = "PASS";
        }

        return item;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setState,
  nextUtterance,
  retakeUtterance,
  removeCompleted,
  toggleSilenceDetector,
  toggleAutoStart,
  setAutoStartCount,
  setAudioDeviceId,
  setAudioDeviceName,
  toggleAutoGain,
  setDeviceQuality,
  setSilenceDetectorThresholds,
  setHasRunCalibration,
  setCountdownLength,
  setPlayback,
  setIsPlaying,
  prepareUtterances,
  toggleIsPaused,
  updateUtterance,
} = recorderSlice.actions;

export default recorderSlice.reducer;
