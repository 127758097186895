import { useEventListener } from "./useEventListener";

export const useEnterKey = (callback: () => void) => {
  useEventListener("keydown", keyHandler);

  // TODO find type for this
  // eslint-disable-next-line
  function keyHandler(e: any) {
    if (e.key === "Enter") {
      callback();
    }
  }
};
