// ====================================================================================
// Voice Cloner Header on auth pages
// ====================================================================================
import React from "react";
import { Divider, Flex, Heading } from "@chakra-ui/react";
import { ParrotLogo } from "@assets";

type Props = {
  title: string;
};

export const AuthHeader: React.FC<Props> = ({ title }) => {
  return (
    <>
      <Flex align="center" justify="center" my={4}>
        <ParrotLogo boxSize={12} />
        <Heading ml={4}>Voice Cloner</Heading>
      </Flex>
      <Divider my={8} />
      <Heading size="md" my={3}>
        {title}
      </Heading>
    </>
  );
};
