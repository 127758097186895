import {
  FormControl,
  FormLabel,
  Box,
  Switch,
  FormHelperText,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useRecorderState } from "@hooks";
import { toggleAutoGain } from "@store";

/**
 * Toggle Switch for Autogain control - Staging ONLY
 */
export const AutoGain = () => {
  const { autoGain, recorderState } = useRecorderState();
  const dispatch = useDispatch();

  return (
    <FormControl alignItems="center" my={8}>
      <Box display="flex">
        <FormLabel htmlFor="hands-free" mb="0" textAlign="center">
          Auto gain
        </FormLabel>
        <Switch
          id="hands-free"
          value="true"
          isChecked={autoGain}
          isDisabled={recorderState !== "IDLE"}
          onChange={() => dispatch(toggleAutoGain())}
        />
      </Box>
      <FormHelperText>In testing - staging only!</FormHelperText>
    </FormControl>
  );
};
