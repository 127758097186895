// ====================================================================================
// Redux reducers and thunks for the Voice Builder
// ====================================================================================
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { handleError } from "@utils";
import { VoiceBuilder } from "../api/VoiceBuilder";

export interface BuildStatusInterface {
  createdAt: number;
  gender: string;
  name: string;
  languageCode: string;
  id: string;
  uuid: string;
  metrics: object;
  status: string;
  numberOfUtterancesUsed: number;
  trainingDataDuration: number;
  trainingTime: number;
  userId: string;
  verticalsUsed: string[];
}

type VBStatus = "INITIALISING" | "BUILDING" | "COMPLETE" | "UNKNOWN";

export interface BuildState {
  buildStatus: BuildStatusInterface | undefined;
  buildStatusLoading: boolean;
  buildStatusError: string;
  buildStarted: boolean;
  buildLoading: boolean;
  buildError: string;
  state: VBStatus;
  previews: string[];
}

const initialState: BuildState = {
  buildStatus: undefined,
  buildStatusLoading: false,
  buildStatusError: "",
  buildStarted: false,
  buildLoading: false,
  buildError: "",
  state: "UNKNOWN",
  previews: [],
};

/*===================================================================*/
/* Async Thunks
/*===================================================================*/

/**
 * Gets the build previews
 */
export const buildPreviews = createAsyncThunk(
  "buildPreviews",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await VoiceBuilder.getPreview(id);
      if (res) return res;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);
/**
 * Gets the status of the build
 */
export const buildStatus = createAsyncThunk(
  "buildStatus",
  async (username: string, { rejectWithValue, dispatch }) => {
    try {
      const res = (await VoiceBuilder.getBuildStatus()) as BuildStatusInterface;
      if (res) {
        if (res.status === "ready") {
          dispatch(buildPreviews(res.id));
        }
        return res;
      }
    } catch (error) {
      // Needs to handle 404
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Gets the status of the build
 */
export const buildStart = createAsyncThunk(
  "buildStart",
  async (_, { rejectWithValue }) => {
    try {
      return (await VoiceBuilder.startBuild()) as BuildStatusInterface;
    } catch (error) {
      if (handleError(error) === "Request failed with status code 403") {
        return rejectWithValue("You need an enterprise plan to build a voice");
      }
      return rejectWithValue(handleError(error));
    }
  }
);

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

/**
 * Build Reducer
 */
export const buildSlice = createSlice({
  name: "build",
  initialState,
  reducers: {
    // Sets the state of the voice build
    setBuildState: (state, action: PayloadAction<VBStatus>) => {
      state.state = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Build Status
    builder.addCase(buildStatus.pending, (state) => {
      state.buildStatusLoading = true;
      state.buildStatusError = "";
      state.buildStatus = undefined;
    });
    builder.addCase(
      buildStatus.fulfilled,
      (state, action: PayloadAction<BuildStatusInterface | undefined>) => {
        state.buildStatusLoading = false;
        state.buildStatusError = "";
        if (action.payload) state.buildStatus = action.payload;
      }
    );
    builder.addCase(buildStatus.rejected, (state, action) => {
      state.buildStatusLoading = false;
      // @ts-expect-error Will always be string
      state.buildStatusError = action.payload;
    });

    /**
     * Build preview endpoint, dont care about about pending and errors katm
     */
    builder.addCase(
      buildPreviews.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.previews = action.payload;
      }
    );

    // Build Start
    builder.addCase(buildStart.pending, (state) => {
      state.buildStarted = false;
      state.buildError = "";
      state.buildLoading = true;
    });
    builder.addCase(
      buildStart.fulfilled,
      (state, action: PayloadAction<BuildStatusInterface | undefined>) => {
        state.buildLoading = false;
        state.buildError = "";
        state.buildStarted = true;
        if (action.payload) state.buildStatus = action.payload;
        state.state = "INITIALISING";
      }
    );
    builder.addCase(buildStart.rejected, (state, action) => {
      state.buildLoading = false;
      state.buildStarted = false;
      // @ts-expect-error Will always be string
      state.buildError = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setBuildState } = buildSlice.actions;

export default buildSlice.reducer;
