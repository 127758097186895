// ====================================================================================
// Voice Builder API
// ====================================================================================
import API from "@aws-amplify/api";

export const VoiceBuilder = {
  /**
   * Checks the status of the users voice model
   */
  getBuildStatus: async () => {
    try {
      const data = await API.get("voiceStatus", ``, {});
      return data.data[data.data.length - 1];
    } catch (e) {
      throw e;
    }
  },

  /**
   * Gets the audio previews
   * @param id id of the model
   */
  getPreview: async (id: string) => {
    try {
      const data = await API.get("preview", `?id=${id}`, {});
      return data.data.previewFiles;
    } catch (e) {
      throw e;
    }
  },

  /**
   * Starts building the users voice model
   */
  startBuild: async () => {
    const data = await API.post("voiceBuild", ``, {});
    return data.data;
  },
};
