import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, buildStart, buildStatus, setBuildState } from "@store";
import { useAuthState, useBuildState } from "@hooks";

/**
 * Hook for voice building on the dashboard
 */
export const useVoiceBuilder = () => {
  const { data, error, status, isLoading } = useBuildState();
  const { name } = useAuthState();
  const [msg, setMsg] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Check the status of the build
   */
  useEffect(() => {
    if (!name) return;
    dispatch(buildStatus(name));
    // eslint-disable-next-line
  }, [name]);

  /**
   * Update the status when we get new info, convert resemble types to my types
   */
  useEffect(() => {
    switch (data?.status) {
      case "starting":
        dispatch(setBuildState("INITIALISING"));
        setMsg(
          "Your voice model has started being built. We will send you an email once it is completed."
        );
        break;
      case "training":
        dispatch(setBuildState("BUILDING"));
        setMsg(
          "Your voice model is now being built by our AI. We will send you an email once it is completed."
        );
        break;
      // TODO fix this
      // case "failed":
      //   dispatch(setBuildState("BUILDING"));
      //   setMsg(
      //     "An error occurred while building your model"
      //   );
      //   break;
      case "ready":
        dispatch(setBuildState("COMPLETE"));
        setMsg("Your voice model has been built and is now ready to use!");
        break;
      default:
        dispatch(setBuildState("UNKNOWN"));
        setMsg("");
    }
    // eslint-disable-next-line
  }, [data]);

  /**
   * Starts building the voice model
   */
  const start = () => {
    dispatch(buildStart());
  };

  return {
    start,
    data,
    status,
    error,
    msg,
    isError: error.length > 0,
    isLoading,
  };
};
