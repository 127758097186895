// ====================================================================================
// Bottom Bar Feature with visualiser and controls
// ====================================================================================
import { Flex } from "@chakra-ui/react";
import { LevelMeter, Controls, BarContainer, Waveform } from "./components";

/**
 * Bottom Bar Component
 */
export const BottomBar = () => {
  return (
    <BarContainer>
      <Flex
        justify="space-between"
        align="center"
        maxWidth="1680px"
        width="100%"
      >
        <LevelMeter />
        <Waveform width={600} height={67} />
        <Controls />
      </Flex>
    </BarContainer>
  );
};
