// ====================================================================================
// Individual Card component
// ====================================================================================
import React from "react";
import { Utterance } from "@types";
import { Flex, Text, Box } from "@chakra-ui/react";
import { ErrorIcon, LoadingIcon, SuccessIcon } from "@assets";
import { CardStyled } from "./Card.styled";
import { CardErrors } from "./CardErrors";
import { CardControls } from "./CardControls";
import { useRecorderState } from "@hooks";
import { WordHighlight } from "../WordHighlight";
import { useColorCalc } from "../../hooks/useColorCalc";

type Props = {
  data: Utterance;
  current?: boolean;
  recordOnClick?: () => void;
};

/**
 * Card to display the different types of utterances
 * @param data
 * @param current boolean
 * @param recordOnClick
 * @constructor
 */
export const Card: React.FC<Props> = ({
  data,
  current = false,
  recordOnClick,
}) => {
  const { recorderState } = useRecorderState();

  const missedIndices = React.useMemo(
    () =>
      data?.errors?.filter((item) => item.type === "TEXT_COVERAGE")[0]
        ?.missedIndices,
    [data]
  );

  const cardColors = useColorCalc(current, recorderState, data.status);

  return (
    <Flex
      align="center"
      id={current ? "focused" : ""}
      width="100%"
      justifyContent="center"
      data-testid="utt-card"
    >
      <Box width={{ base: 0, xl: "120px" }} />
      {/*Main Card Body*/}
      <CardStyled
        bg={cardColors.bg}
        outlineColor={cardColors.outlineColor}
        outline={cardColors.outline}
      >
        <Flex
          justify="space-between"
          direction="column"
          flexGrow="1"
          alignItems="center"
        >
          {/* Utterance Text */}
          <Text
            fontSize="xl"
            textAlign="center"
            fontWeight={current ? "bold" : ""}
          >
            {missedIndices && missedIndices.length > 0 ? (
              <WordHighlight text={data?.text} indices={missedIndices} />
            ) : (
              <>{data?.text}</>
            )}
          </Text>
          {data.status === "FAIL" && (
            <CardErrors status={data.status} errors={data.errors} />
          )}
        </Flex>
        {/* Icon for completed cards */}
        {data.status === "PASS" && <SuccessIcon boxSize={8} />}
        {data.status === "LOADING" && <LoadingIcon boxSize={8} />}
        {data.status === "FAIL" && <ErrorIcon boxSize={8} />}
        {/* {data.status === "LOADING" && (
          <Spinner boxSize={6} emptyColor="gray.300" minWidth={6} />
        )} */}
      </CardStyled>

      {/* Controls to the right of the card */}
      <CardControls
        status={data.status}
        id={data?.id}
        waveData={data?.waveform}
        current={current}
        recordOnClick={recordOnClick}
        url={data?.audio}
      />
    </Flex>
  );
};
