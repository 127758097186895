// ====================================================================================
// Buttons for a stage on the dashboard
// ====================================================================================
import { MenuStage } from "@types";
import { Box, Heading } from "@chakra-ui/react";
import { LockIcon, ScriptIcon, SuccessIcon } from "@assets";

/**
 * Individual stage card
 * @param props
 */
export const StageCard = (props: {
  stage: MenuStage;
  selectedStage: MenuStage | undefined;
  onClick: (item: MenuStage) => void;
}) => {
  const complete = props.stage.complete;
  let color = "blue";
  if (props.stage.disabled) color = "gray";
  if (complete) color = "green";

  const intensity =
    props.selectedStage && props.selectedStage.name === props.stage.name
      ? "100"
      : "50";

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      bg={`${color}.${intensity}`}
      borderRadius="6px"
      _hover={{ bg: `${color}.100` }}
      p={4}
      onClick={() => props.onClick(props.stage)}
      cursor="pointer"
      borderWidth="1px"
      borderColor={`${color}.200`}
    >
      {complete && <SuccessIcon boxSize={8} />}
      {!complete && !props.stage.disabled && <ScriptIcon boxSize={8} />}
      {!complete && props.stage.disabled && <LockIcon boxSize={8} />}

      <Heading fontSize="lg" ml={4} overflowX="hidden">
        {props.stage.name}
      </Heading>
    </Box>
  );
};
