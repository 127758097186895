// ====================================================================================
// Record / Play / Retake controls for a card
// ====================================================================================
import { useRecorderState } from "@hooks";
import { RecordButton } from "@components";
import { IconButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { CardControlsStyled } from "./Card.styled";
import { FaPlay, FaStop } from "react-icons/fa";
import { useRetake, usePlayback } from "../../hooks";

/**
 * Controls for the right side of the card
 * @param props
 * @constructor
 */
export function CardControls(props: {
  status: "PASS" | "FAIL" | "CURRENT" | "LOADING" | undefined;
  current: boolean | undefined;
  recordOnClick: (() => void) | undefined;
  id: string;
  url?: string;
  waveData?: number[];
}) {
  const { recorderState, autoStart, autoStartCount, isPaused } =
    useRecorderState();
  const { play, playbackId, playbackIsPlaying } = usePlayback();
  const { retakeOnClick } = useRetake();

  return (
    <CardControlsStyled>
      {/* Play and Retake buttons */}
      {(props.status === "PASS" ||
        props.status === "FAIL" ||
        props.status === "LOADING") && (
        <>
          <IconButton
            aria-label="Retake Utterance"
            icon={<RepeatIcon />}
            colorScheme="gray"
            isRound
            onClick={() => retakeOnClick(props.id)}
            data-testid="retake"
          />
          <IconButton
            aria-label="Playback Utterance"
            icon={
              playbackIsPlaying && playbackId === props.id ? (
                <FaStop />
              ) : (
                <FaPlay />
              )
            }
            colorScheme="gray"
            isRound
            onClick={() => play(props.id)}
            data-testid="playback"
          />
        </>
      )}
      {/* Record Button */}
      {props.current && props.status === undefined && props.recordOnClick && (
        <RecordButton
          recorderState={recorderState}
          onClick={props.recordOnClick}
          isCountdown={autoStart && recorderState === "IDLE"}
          count={autoStartCount}
          isPaused={isPaused}
        />
      )}
    </CardControlsStyled>
  );
}
