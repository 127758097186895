import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "@store";
import { checkUser } from "@store";

/**
 * Checks the users login status on page load
 */
export const useSessionValidator = () => {
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkUser());
    // eslint-disable-next-line
  }, [loggedIn]);
};
