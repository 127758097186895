// ====================================================================================
// Redux config and exports
// ====================================================================================

import { configureStore } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import recorderReducer from "./recorderSlice";
import appReducer from "./appSlice";
import authReducer from "./authSlice";
import buildReducer from "./buildSlice";
import userReducer from "./userSlice";
import verticalsReducer from "./verticalsSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    build: buildReducer,
    recorder: recorderReducer,
    verticals: verticalsReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(LogRocket.reduxMiddleware()),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
