// ====================================================================================
// Review Page - Allows the user to review and retake utterancex
// ====================================================================================
import {
  Box,
  Button,
  Flex,
  Select,
  FormLabel,
  Skeleton,
  Heading,
  Text,
  Divider,
} from "@chakra-ui/react";
import { Panel } from "@components";
import { useReview } from "./hooks/useReview";
import { ReviewCard } from "./components/ReviewCard";
import { ReviewPlayer } from "./components/ReviewPlayer";

/**
 * Main Page component
 */
export const Review = () => {
  const review = useReview();

  return (
    <Panel title="Review Recordings">
      <Skeleton isLoaded={!review.dataLoading} mt={8}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={8}
          flexDirection="column"
        >
          <Text>
            Here you can review and retake your utterances as needed. Click an
            utterance to select it and then press retake.
          </Text>
          <Flex
            gap={4}
            minWidth="330px"
            width="100%"
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ base: "center", md: "flex-end" }}
          >
            <Box>
              <FormLabel>Select Script</FormLabel>

              {/* Vertical and Retake Controls */}
              <Select
                value={review.selectedVertical}
                onChange={(e) => review.setSelectedVertical(e.target.value)}
              >
                {review.verticalList.map((vertical) => (
                  <option key={vertical.verticalId} value={vertical.verticalId}>
                    {vertical.name || vertical.verticalId}
                  </option>
                ))}
              </Select>
            </Box>
            <Button
              size="md"
              onClick={() => review.start()}
              isDisabled={!review.hasChosen}
            >
              Retake Selected
            </Button>
          </Flex>
        </Flex>
        <Divider my={8} />
        <Heading fontSize="xl" mt={8}>
          Utterances
        </Heading>

        {/* Utterance Cards */}
        {review.data?.map((utterance) => (
          <Flex justifyContent="space-between" my={4} key={utterance.id}>
            <ReviewCard
              text={utterance.text}
              selected={utterance.selected}
              onClick={() => review.select(utterance.id)}
            />
            <ReviewPlayer
              utterance={utterance}
              playbackId={review.playbackId}
              playbackLoading={review.playbackLoading}
              onClick={() => review.getFile(utterance.id)}
            />
          </Flex>
        ))}
      </Skeleton>
    </Panel>
  );
};
