// ====================================================================================
// Help / Tutorial Page
// ====================================================================================
import React from "react";
import { Panel } from "@components";
import { Heading, Text } from "@chakra-ui/react";

/**
 * Page for the tutorial video and FAQ
 */
export const Tutorial: React.FC = () => {
  return (
    <Panel title="Help">
      <Heading fontSize="xl" mb={4} mt={8}>
        Basic Controls
      </Heading>
      <Heading fontSize="xl" mb={4} mt={8}>
        Keyboard Shortcuts
      </Heading>
      <Text mb={2}>
        <b>Spacebar</b> - Starts and stops the recorder.
      </Text>
      <Text mb={2}>
        <b>Backspace</b> - Deletes the last recorded utterance and moves it back
        to be rerecorded.
      </Text>

      <Heading fontSize="xl" mb={4} mt={8}>
        Hands Free Mode
      </Heading>
    </Panel>
  );
};
