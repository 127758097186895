// ====================================================================================
// Userpools - congnito config and endpoint url settings for prod and staging
// ====================================================================================

// staging
export const staging = {
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_TkAVCiZ4j",
    APP_CLIENT_ID: "492bhb0egleu9au86fqvt94r4v",
    IDENTITY_POOL_ID: "eu-west-1:200e9432-6909-4ced-904b-e94c814468ce",
  },
  userData: "https://staging-v1.api.audio/user/",
  voiceBuild: "https://staging-v1.api.audio/voice-cloner/training",
  voiceStatus: "https://staging-v1.api.audio/voice-cloner/models",
  getUserData: "https://staging-v1.api.audio/voice-cloner/user",
  getText: "https://staging-v1.api.audio/voice-cloner/recording",
  aesFeedback: "https://staging-v1.api.audio/voice-cloner/feedback",
  upload: "https://staging-v1.api.audio/voice-cloner/recording",
  preview: "https://staging-v1.api.audio/voice-cloner/preview",
  getFile: "https://staging-v1.api.audio/ulvc2/files",
};
// prod
export const prod = {
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_j3qbxlkAS",
    APP_CLIENT_ID: "3fo2sfuisb9jkkmmrle68ehh6c",
    IDENTITY_POOL_ID: "eu-west-1:5550f701-adf2-45f5-abf3-49e99fdfc8ba",
  },
  userData: "https://v1.api.audio/user/",
  voiceBuild: "https://v1.api.audio/voice-cloner/training",
  voiceStatus: "https://v1.api.audio/voice-cloner/models",
  getUserData: "https://v1.api.audio/voice-cloner/user",
  getText: "https://v1.api.audio/voice-cloner/recording",
  aesFeedback: "https://v1.api.audio/voice-cloner/feedback",
  upload: "https://v1.api.audio/voice-cloner/recording",
  preview: "https://v1.api.audio/voice-cloner/preview",
  getFile: "https://v1.api.audio/ulvc2/files",
};
