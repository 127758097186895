import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { PanelStyled } from "./PanelStyled";

type Props = {
  isSmall?: boolean;
  title?: string;
  hasBottomBar?: boolean;
  children: React.ReactNode;
};

export const Panel: React.FC<Props> = ({
  isSmall = false,
  title,
  children,
}) => {
  return (
    <Box
      height={{ md: "100%" }}
      display={isSmall ? "grid" : "block"}
      placeItems={isSmall ? "center" : ""}
      margin={!isSmall ? { base: 0, md: "32px auto" } : { base: 0, md: 0 }}
    >
      <PanelStyled
        width={
          isSmall
            ? { base: "100%", md: "430px" }
            : { base: "100%", md: "768px" }
        }
        data-testid="panel"
        my={{ base: 0, md: 8 }}
      >
        {title && (
          <Heading ml={{ base: 16, md: 0 }} mt={{ base: 3, md: 4 }} mb={8}>
            {title}
          </Heading>
        )}
        {children}
      </PanelStyled>
    </Box>
  );
};
