// ====================================================================================
// Builds the stages from the verticals and state, handles actions
// ====================================================================================
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuStage } from "@types";
import { useBuildState, useRecorderState, useVerticalsState } from "@hooks";
import {
  stageAudioSetup,
  stageBuildModel,
  stageMissingScripts,
  generateStagesFromVerticals,
} from "./verticalUtils";

/**
 * Logic for stages
 * @param recordOnClick callback to move to record page
 * @param buildOnClick callback to start the voice builder
 */
export const useStages = (
  recordOnClick: () => void,
  buildOnClick: () => void
) => {
  const { verticals } = useVerticalsState();
  const { hasRunCalibration } = useRecorderState();
  const { status } = useBuildState();
  const [selectedStage, setSelectedStage] = useState<MenuStage | undefined>();
  const [menuStages, setMenuStages] = useState<MenuStage[]>([]);
  const navigate = useNavigate();

  /**
   * Compiles the list of stages for the dashboard
   * @returns MenuStage[]
   */
  const buildStages = useCallback((): MenuStage[] => {
    const allStages = generateStagesFromVerticals(verticals, hasRunCalibration);

    // If there are no scripts to be loaded
    if (allStages.length === 0) {
      const missingScriptsStage = stageMissingScripts();
      allStages.push(missingScriptsStage);
      return allStages;
    }

    // Add audio setup to top of the list
    const setupStage = stageAudioSetup(hasRunCalibration);
    allStages.unshift(setupStage);

    // Insert build stage at position 3
    const buildStage = stageBuildModel(status, verticals);
    allStages.splice(3, 0, buildStage);

    return allStages;
  }, [hasRunCalibration, verticals, status]);

  /**
   * Updates the stages when the vertical info changes
   */
  useEffect(() => {
    const stages = buildStages();
    // Automatically select the first incomplete stage and save state
    setSelectedStage(stages.find((item) => !item.complete));
    setMenuStages(stages);
  }, [buildStages]);

  /**
   * Handles when the user clicks an action button
   */
  const onStart = () => {
    if (!selectedStage) return;
    if (selectedStage.type === "SETUP") navigate("/calibration");
    if (selectedStage.type === "VERTICAL") recordOnClick();
    if (selectedStage.type === "MODEL") buildOnClick();
  };

  return {
    menuStages,
    selectedStage,
    setSelectedStage,
    onStart,
  };
};
