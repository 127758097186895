// ====================================================================================
// Functions for audio processing
// ====================================================================================

/**
 * Calculates the rms of a float 32 array for buffer or range
 * @param floatBuffer
 * @param start number of sample to start from
 * @param samples number of samples
 */
export function bufferToRmsDb(
  floatBuffer: Float32Array,
  start = 0,
  samples: number = floatBuffer.length
) {
  let rms = 0;
  for (let i = start; i < samples; i += 1) {
    rms += floatBuffer[i] * floatBuffer[i];
  }
  rms /= floatBuffer.length;
  rms = Math.sqrt(rms);
  return dbFromFloat(rms);
}

/**
 * Calculates the peak db for buffer or range
 * @param floatBuffer
 * @param start number of sample to start from
 * @param samples number of samples
 */
export function bufferToPeakDb(
  floatBuffer: Float32Array,
  start = 0,
  samples: number = floatBuffer.length
) {
  let peakDb = 0;
  for (let i = start; i < start + samples; i += 1) {
    const sample = Math.abs(floatBuffer[i]);
    if (peakDb <= sample) peakDb = sample;
  }
  return dbFromFloat(peakDb);
}

/**
 * Converts arrayBuffer to float32array and runs calculations
 * @param data
 */
export async function arrayBufferToDb(data: Blob): Promise<{
  peakDb: number;
  rmsDb: number;
}> {
  let peakDb = -100.0;
  let rmsDb = -100.0;
  await data.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
    const floatBuffer = convertBlock(arrayBuffer);
    rmsDb = bufferToRmsDb(floatBuffer);
    peakDb = bufferToPeakDb(floatBuffer);
  });
  return { peakDb, rmsDb };
}

/**
 * Converts arrayBuffer to float32Array
 */
export async function arrayBufferToFloat32(data: Blob): Promise<Float32Array> {
  return data.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
    return convertBlock(arrayBuffer);
  });
}

/**
 * Converts data from an arrayBuffer (16bit) to Float32 Array
 * Removes the 44 bytes of metadata and returns the audio values
 * @param buffer {ArrayBuffer}
 */
export function convertBlock(buffer: ArrayBuffer): Float32Array {
  const incomingData = new Int16Array(buffer, 44);
  const outputData = new Float32Array(incomingData.length);
  for (let i = 0; i < incomingData.length; i += 1) {
    outputData[i] = incomingData[i] / (65535 - 1 - 32767);
  }
  return outputData;
}
/**
 * Converts Float value to Db
 * @param {*} floatVal
 * @returns {Number} Value in db
 */
export function dbFromFloat(floatVal: number): number {
  return (Math.log(Math.abs(floatVal)) / Math.log(10)) * 20;
}
