import { useSelector } from "react-redux";
import { RootState } from "@store";

/**
 * Access to recorder state
 */
export const useRecorderState = () => {
  const recorderState = useSelector((state: RootState) => state.recorder.state);
  const upcoming = useSelector((state: RootState) => state.recorder.upcoming);
  const completed = useSelector((state: RootState) => state.recorder.completed);
  const autoStart = useSelector((state: RootState) => state.recorder.autoStart);
  const isPaused = useSelector((state: RootState) => state.recorder.isPaused);
  const audioDeviceId = useSelector(
    (state: RootState) => state.recorder.audioDeviceId
  );
  const autoGain = useSelector((state: RootState) => state.recorder.autoGain);
  const playbackId = useSelector(
    (state: RootState) => state.recorder.playbackId
  );
  const playbackIsPlaying = useSelector(
    (state: RootState) => state.recorder.playbackIsPlaying
  );
  const countdownLength = useSelector(
    (state: RootState) => state.recorder.countdownLength
  );
  const autoStartCount = useSelector(
    (state: RootState) => state.recorder.autoStartCount
  );
  const silenceDetectorFirstSoundThreshold = useSelector(
    (state: RootState) => state.recorder.silenceDetectorFirstSoundThreshold
  );
  const silenceDetectorSilenceThreshold = useSelector(
    (state: RootState) => state.recorder.silenceDetectorSilenceThreshold
  );
  const silenceDetectorActive = useSelector(
    (state: RootState) => state.recorder.silenceDetectorActive
  );
  const hasRunCalibration = useSelector(
    (state: RootState) => state.recorder.hasRunCalibration
  );
  const deviceQuality = useSelector(
    (state: RootState) => state.recorder.deviceQuality
  );
  const previousPage = useSelector(
    (state: RootState) => state.recorder.previousPage
  );

  return {
    recorderState,
    upcoming,
    completed,
    autoStart,
    isPaused,
    audioDeviceId,
    autoGain,
    playbackId,
    playbackIsPlaying,
    countdownLength,
    autoStartCount,
    silenceDetectorSilenceThreshold,
    silenceDetectorFirstSoundThreshold,
    silenceDetectorActive,
    hasRunCalibration,
    deviceQuality,
    previousPage,
  };
};
