// ====================================================================================
// Main App Entry point + Router and Globals
// ====================================================================================
import React, { useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuthState, useSessionValidator, useShowBottomBar } from "@hooks";
import { HelpModal, Menu, PageContainer } from "@components";
import { RecorderContext } from "@context";
import {
  Record,
  Calibration,
  BottomBar,
  Auth,
  Settings,
  Dashboard,
  Tutorial,
  Review,
} from "@features";
import { Recorder } from "@utils/recorder";
import { Box, useColorModeValue } from "@chakra-ui/react";

/**
 * Main entry point and application router
 */
export const App: React.FC = () => {
  const recorderRef = useRef<Recorder>();
  const audioStreamRef = useRef<MediaStream | undefined>();
  const { loggedIn } = useAuthState();
  const showBottomBar = useShowBottomBar();
  const themeMode = useColorModeValue("light", "dark");
  useSessionValidator();

  return (
    <Box height="100vh">
      <RecorderContext.Provider
        value={{
          recorderRef,
          audioStreamRef,
        }}
      >
        {/* Global components */}
        {loggedIn && (
          <>
            <HelpModal />
            <Menu hasBottomBar={showBottomBar} />
          </>
        )}
        {/* Pages */}
        <PageContainer $hasBottomBar={showBottomBar} $themeMode={themeMode}>
          <Routes>
            {loggedIn && (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/record" element={<Record />} />
                <Route path="/calibration" element={<Calibration />} />
                <Route path="/review" element={<Review />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/tutorial" element={<Tutorial />} />
                <Route path="/signup" element={<Auth />} />
              </>
            )}
            {!loggedIn && (
              <>
                <Route path="/" element={<Auth />} />
                <Route path="/signup" element={<Auth />} />
              </>
            )}
          </Routes>
        </PageContainer>
        {/* Bottom Bar */}
        {showBottomBar && <BottomBar />}
      </RecorderContext.Provider>
    </Box>
  );
};
