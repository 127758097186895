// ====================================================================================
// ReviewCard - Displays the utterance
// ====================================================================================
import { Box, Text } from "@chakra-ui/react";

export const ReviewCard = (props: {
  text: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <Box
      onClick={() => props.onClick()}
      bg={props.selected ? "green.50" : "blue.50"}
      cursor="pointer"
      width="100%"
      maxWidth="768px"
      borderWidth={props.selected ? "4px" : "0"}
      borderColor="green.200"
      borderRadius="6px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={4}
      minHeight="120px"
      fontSize="24px"
      fontWeight="semibold"
      transition="0.2s ease"
    >
      <Text fontSize="lg" textAlign="center">
        {props.text}
      </Text>
    </Box>
  );
};
