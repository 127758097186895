import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

/**
 * Main Style for the Card
 */
export const CardStyled = styled(Box)`
  width: 100%;
  max-width: 768px;
  min-height: 125px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease;
`;

CardStyled.defaultProps = {
  p: 6,
};

/**
 * Style for the controls
 */
export const CardControlsStyled = styled.div`
  height: 100%;
  width: 120px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 6px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 60px;
  }
`;

/**
 * Styles for the Error Bubbles
 */
export const ErrorBubble = styled(Box)`
  display: grid;
  place-items: center;
  border-radius: 1rem;
  padding: 4px 10px;
  margin-right: 8px;
  cursor: pointer;
`;

ErrorBubble.defaultProps = {
  _dark: {
    bg: "card.errorBubble-dark",
  },
  _light: {
    bg: "card.errorBubble-light",
  },
};
