/*===================================================================*/
/* Helpers
/*===================================================================*/

/**
 * Fixes error types and makes them into a string
 * @param error
 * @returns error message as a string
 */
export const handleError = (error: string | Error | unknown): string => {
  let message: string;
  if (error instanceof Error) message = error.message;
  else message = String(error);
  return message;
};

/**
 * Converts an integer to hexidecimal
 * @param nr number to convert
 */
export function intToHex(nr: number): string {
  return nr.toString(16).padStart(2, "0");
}

/**
 * Generates a random string for dummy password
 * @param bytes length of the string in bytes
 */
export function getRandomString(bytes: number): string {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

/**
 * Moves the current utterance into view
 */
export const scrollToUtterance = () => {
  // Wait for the UI to be updated before moving
  setTimeout(() => {
    const current: HTMLElement | null = document.getElementById("focused");
    current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, 100);
};

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius = 12,
  fill = true,
  stroke = false
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}
