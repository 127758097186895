import React, { useEffect, useState } from "react";
import type { AppDispatch, RootState } from "@store";
import { useSelector, useDispatch } from "react-redux";
import { setAudioDeviceId, setAudioDeviceName } from "@store";
import { DropdownContent } from "@types";
import { FormHelperText, Select, FormControl } from "@chakra-ui/react";

/**
 * Allows the user to select their audio input device
 */
export const SelectDevice: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const audioDeviceId = useSelector(
    (state: RootState) => state.recorder.audioDeviceId
  );
  const recorderState = useSelector((state: RootState) => state.recorder.state);
  const [devices, setDevices] = useState<DropdownContent[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<DropdownContent>({
    key: "",
    value: "",
    text: "",
  });

  /**
   * Gets the users current audio devices
   */
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((values) => {
      const filtered = values
        .filter((item) => item.kind === "audioinput")
        .map((item, index) => ({
          key: item.deviceId,
          text: item.label || `Input ${index} ${index === 0 && "(Default)"}`,
          value: item.deviceId,
        }));
      setDevices(filtered);

      // Find the index of the users current device
      const index = filtered.findIndex(
        (device) => device.value === audioDeviceId
      );

      // Set the current device otherwise use the default
      if (index !== -1) setSelectedDevice(filtered[index]);
      else setSelectedDevice(filtered[0]);
    });
    // eslint-disable-next-line
  }, []);

  // Update the store when the device changes
  useEffect(() => {
    dispatch(setAudioDeviceId(selectedDevice.value));
    dispatch(setAudioDeviceName(selectedDevice.text));
    // eslint-disable-next-line
  }, [selectedDevice]);

  return (
    <FormControl>
      <Select
        onChange={(e) => {
          const newValue = devices.filter(
            (item) => item.value === e.target.value
          );
          setSelectedDevice(newValue[0]);
        }}
        isDisabled={recorderState !== "IDLE"}
      >
        {devices.map((item) => (
          <option value={item.value} key={item.key}>
            {item.text}
          </option>
        ))}
      </Select>
      <FormHelperText ml={1}>Select your audio device</FormHelperText>
    </FormControl>
  );
};
