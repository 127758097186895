import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInterval, useRecorderState } from "@hooks";
import { createStream, startRecording, stopRecording } from "@utils";
import { Config } from "@config";
import { RecorderContext } from "@context";
import {
  AppDispatch,
  setHasRunCalibration,
  setSilenceDetectorThresholds,
  setState,
} from "@store";
import {
  checkMicLevel,
  checkNoiseLevel,
  ICalibrationResults,
} from "../utils/CalculateResults";

type Stages = "MIC_CHECK" | "NOISE_CHECK";

/**
 * Handles the calibration recording logic
 */
export const useCalibrationRecorder = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { audioStreamRef, recorderRef } = useContext(RecorderContext);
  const { recorderState, audioDeviceId, autoGain } = useRecorderState();
  const [stage, setStage] = useState<Stages>("MIC_CHECK");
  const [timer, setTimer] = useState<number>(1);
  const [micResult, setMicResult] = useState<ICalibrationResults | undefined>();
  const [noiseResult, setNoiseResult] = useState<
    ICalibrationResults | undefined
  >();

  /**
   * Handles the data when the recording has stopped
   * @param data
   */
  async function postRecordHandler(data: Blob) {
    if (stage === "MIC_CHECK") {
      const res = await checkMicLevel(data);
      setMicResult(res);
    }
    if (stage === "NOISE_CHECK" && micResult) {
      const res = await checkNoiseLevel(data, micResult.peakDb);
      setNoiseResult(res);
    }
  }

  /**
   * Handles starting the recorder if in ARM
   */
  useEffect(() => {
    if (recorderState !== "ARM") return;
    async function run() {
      await createStream(audioStreamRef, audioDeviceId, autoGain);
      startRecording(audioStreamRef, recorderRef, postRecordHandler);
      dispatch(setState("RECORDING"));
    }
    run();
    // eslint-disable-next-line
  }, [recorderState]);

  /**
   * Handles stopping after 5 seconds
   */
  useInterval(
    () => {
      if (timer < 5) {
        setTimer(timer + 1);
      } else {
        stopRecording(audioStreamRef, recorderRef);
        dispatch(setState("IDLE"));
        setTimer(1);
      }
    },
    1000,
    recorderState === "RECORDING"
  );

  /**
   * onClick controller for record button
   */
  const recordOnClick = (): void => {
    if (stage === "MIC_CHECK") setMicResult(undefined);
    if (stage === "NOISE_CHECK") setNoiseResult(undefined);
    if (recorderState === "IDLE") dispatch(setState("ARM"));
  };

  /**
   * onClick for 2nd stage - Save the results when finished
   */
  const saveOnClick = (): void => {
    if (noiseResult && micResult) {
      dispatch(
        setSilenceDetectorThresholds({
          firstSoundThreshold: micResult.rmsDb - Config.calibrationMoveResults,
          silenceThreshold: noiseResult.rmsDb + Config.calibrationMoveResults,
        })
      );
      dispatch(setHasRunCalibration(true));
      navigate("/", { replace: true });
    }
  };

  /**
   * Move to next stage
   */
  const nextOnClick = (): void => {
    setStage("NOISE_CHECK");
  };

  return {
    saveOnClick,
    recordOnClick,
    nextOnClick,
    timer,
    stage,
    micResult,
    noiseResult,
    recorderState,
  };
};
