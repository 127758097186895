import { useSelector } from "react-redux";
import { RootState } from "@store";

/**
 * Access to data state
 */
export const useBuildState = () => {
  const data = useSelector((state: RootState) => state.build.buildStatus);
  const error = useSelector((state: RootState) => state.build.buildStatusError);
  const status = useSelector((state: RootState) => state.build.state);
  const isLoading = useSelector((state: RootState) => state.build.buildLoading);
  const previewUrls = useSelector((state: RootState) => state.build.previews);

  const buildError = useSelector((state: RootState) => state.build.buildError);
  return {
    data,
    error,
    status,
    isLoading,
    previewUrls,
    buildError,
  };
};
